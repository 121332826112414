import React, { useContext, useRef } from "react";

import {
  ModalCell,
  Switch,
  PrimaryButton,
  ModalSpacer,
  ModalLabel,
  ModalTextArea,
  ModalHeader,
} from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { ReactComponent as FilterSVG } from "@assets/filter.svg";
import { ReactComponent as NavRightSVG } from "@assets/nav-right.svg";
import { ReactComponent as NotificationSVG } from "@assets/notification.svg";
import { isNewPts } from "@services/ptsUtils";
import { HomeContext } from "@services/react";
import { URLS } from "@services/router";
import { FiltersStore } from "@stores/FiltersStore";

export const PtsMainModalScreen = observer(({ filtersStore }: { filtersStore: FiltersStore }) => {
  const navigate = useNavigate();
  const { ptsPageStore } = useContext(HomeContext);
  const { ptsData, onPtsChange, onSubmitPts, setPtsMainModalScreen, closeModal } = ptsPageStore;
  const intl = useIntl();
  const isSaving = useRef(false);

  if (!ptsData) {
    return null;
  }

  const { active, description } = ptsData;

  const handleSubmitPts = async () => {
    if (isSaving.current) {
      return;
    }
    isSaving.current = true;

    const {
      aircraftFilters: { requiredAircraftTypes, excludedAircraftTypes },
      inboundFlightStatus,
      outboundFlightStatus,
      requiredTurnaroundLength,
    } = filtersStore;

    const submittedPts = await onSubmitPts({
      ...ptsData,
      filters: {
        requiredAircraftTypes,
        excludedAircraftTypes,
        inboundFlightStatus,
        outboundFlightStatus,
        requiredTurnaroundLength,
      },
    });

    if (submittedPts && isNewPts(ptsData)) {
      navigate(`${URLS.PTS}/${submittedPts.id}`);
    }

    closeModal();
  };

  return (
    <>
      <ModalHeader
        onClose={closeModal}
        title={intl.formatMessage({
          defaultMessage: "PTS Settings",
          description: "PTS modal",
        })}
        showBorderBottom={false}
      />

      <ModalCell
        title={intl.formatMessage({
          defaultMessage: "Enabled",
          description: "PTS modal",
        })}
        onClick={() => onPtsChange({ active: !active })}
        leftIcon={<NotificationSVG />}
      >
        <Switch active={active} />
      </ModalCell>

      <ModalCell
        title={intl.formatMessage({
          defaultMessage: "Filters",
          description: "PTS modal",
        })}
        leftIcon={<FilterSVG />}
        rightIcon={<NavRightSVG />}
        onClick={() => setPtsMainModalScreen("filters")}
      />

      <ModalLabel
        title={intl.formatMessage({
          defaultMessage: "Description",
          description: "PTS modal",
        })}
      >
        <ModalTextArea
          value={description}
          placeholder={intl.formatMessage({
            defaultMessage: "Set description text for the PTS...",
            description: "PTS modal",
          })}
          onChange={(value) => onPtsChange({ description: value })}
          maxLength={200}
        />
      </ModalLabel>

      <ModalSpacer />

      <PrimaryButton disabled={false} onClick={handleSubmitPts}>
        <FormattedMessage defaultMessage="Save" description="PTS modal" />
      </PrimaryButton>
    </>
  );
});
