import React, { useContext, useEffect, useState } from "react";

import { Table } from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { IntlShape, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { ActiveCell } from "@components/Common/ActiveCell";
import { GroupHeader } from "@components/Common/GroupHeader";
import { Spinner } from "@components/Common/Spinner";
import { TURN_LENGTH_LABELS } from "@i18n/messages";
import { TResourceValue } from "@models/common";
import { Pts } from "@models/pts";
import { cn, HomeContext } from "@services/react";
import { URLS } from "@services/router";

import s from "./style.module.scss";

type Props = {
  group: TResourceValue;
};

const getColumns = (intl: IntlShape, isInactive = false) => {
  if (isInactive) {
    return [
      {
        title: intl.formatMessage({
          defaultMessage: "No PTS found",
          description: "Pts table columns",
        }),
        width: "100%",
        render: () => null,
      },
    ];
  }

  return [
    {
      title: intl.formatMessage({
        defaultMessage: "Airline",
        description: "Pts table columns",
      }),
      width: "25%",
      render: ({ airline: airline }: Pts) => airline.roleName,
    },
    {
      title: intl.formatMessage({
        defaultMessage: "Description",
        description: "Pts table columns",
      }),
      width: "35%",
      render: ({ description }: Pts) => description,
    },
    {
      title: intl.formatMessage({
        defaultMessage: "Length",
        description: "Pts table columns",
      }),
      width: "35%",
      render: ({ filters }: Pts) =>
        filters.requiredTurnaroundLength
          ? intl.formatMessage(TURN_LENGTH_LABELS[filters.requiredTurnaroundLength])
          : "",
    },
    {
      title: intl.formatMessage({
        defaultMessage: "Active",
        description: "Pts table columns",
      }),
      width: "5%",
      getCellClass: ({ active }: Pts) => cn("bold", { green: active }),
      render: ({ active }: Pts) => <ActiveCell active={active} />,
    },
  ];
};

export const PtsGroup = observer(({ group }: Props) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { ptsPageStore } = useContext(HomeContext);
  const { ptsList, initPtsList, togglePtsActive, ready, onCreateNewPts, deletePtsList, copyPtsList } = ptsPageStore;
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  useEffect(() => {
    void initPtsList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!ready) {
    return <Spinner />;
  }

  const openPts = ({ id }: Pts) => {
    navigate(`${URLS.PTS}/${id}`);
  };

  const handleAddPts = () => {
    onCreateNewPts(group);
  };

  const filteredPtsList = ptsList.filter(({ airline }) => airline.id === group.id);
  const activePtsItems = filteredPtsList.filter(({ active }) => active).map(({ id }) => id);

  const isInactive = filteredPtsList.length === 0;

  return (
    <div>
      <GroupHeader
        singularLabel="PTS"
        pluralLabel="PTS"
        group={group.roleName}
        activeItems={activePtsItems}
        selectedItems={selectedIds}
        onAddItem={handleAddPts}
        onDelete={(ids) => void deletePtsList(ids)}
        onCopy={(ids) => void copyPtsList(ids)}
        onToggleActive={(value) => void togglePtsActive(selectedIds, value)}
      />
      <Table
        className={cn({ [s.tableInactive]: isInactive })}
        columns={getColumns(intl, isInactive)}
        getRowKey={({ id }: Pts) => id}
        items={filteredPtsList}
        selectedItems={selectedIds}
        onSelect={setSelectedIds}
        onRowClick={openPts}
      />
    </div>
  );
});
