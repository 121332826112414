import React, { useCallback, useContext, useEffect } from "react";

import { SecondaryButton } from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router";
import { Spinner } from "@components/Common/Spinner";
import { User } from "@models/user";
import { HomeContext } from "@services/react";
import { URLS } from "@services/router";

import { UserModal } from "./UserModal";
import { UsersMenu } from "./UsersMenu";
import { UsersTable } from "./UsersTable";

import styles from "./style.module.scss";

export const UsersPage = observer(() => {
  const navigate = useNavigate();
  const { userId } = useParams<{ userId?: string }>();

  const { usersPageStore } = useContext(HomeContext);
  const {
    editUser,
    init,
    ready,
    usersMap,
    userModalData: showUserModal,
    nextPage,
    loadMoreUsers,
    limitHasReached,
    moreUsersLoading,
  } = usersPageStore;

  const openUserModal = useCallback((item: User) => editUser(item), []);

  const onScrollHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      nextPage();
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!ready || !userId) {
      return;
    }

    const foundUser = usersMap[userId];
    if (foundUser) {
      openUserModal(foundUser);
      return;
    }

    // Cleanup URL by default
    navigate(URLS.USERS);
  }, [ready]);

  useEffect(() => {
    if (!ready) {
      return;
    }

    if (!showUserModal) {
      navigate(URLS.USERS);
      return;
    }

    if (showUserModal && "id" in showUserModal) {
      navigate(`${URLS.USERS}/${showUserModal.id}`);
    }
  }, [ready, showUserModal, navigate]);

  if (!ready) {
    return <Spinner />;
  }

  return (
    <>
      <UsersMenu />
      <section className={styles.usersPage} onScroll={onScrollHandler}>
        {showUserModal && <UserModal />}
        <UsersTable />
        {!limitHasReached && (
          <section className={styles.pageButtons}>
            <SecondaryButton onClick={loadMoreUsers} colorTheme="dark" disabled={moreUsersLoading}>
              <FormattedMessage defaultMessage="Load more users" description="Users page" />
            </SecondaryButton>
          </section>
        )}
      </section>
    </>
  );
});
