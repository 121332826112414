import React, { ChangeEvent, useContext, useState } from "react";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";

import { ImportButton as BaseImportButton } from "@components/Common/ImportButton";
import { HomeContext } from "@services/react";
import { PtsImportModal } from "../ImportModal";

export const PtsImportButton = observer(() => {
  const intl = useIntl();
  const { ptsPageStore } = useContext(HomeContext);

  const [file, setFile] = useState<null | { content: string; name: string }>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleFileLoad = (event: ChangeEvent<HTMLInputElement>) => {
    setIsProcessing(true);

    const openedFile = event.target?.files?.item(0);

    if (openedFile) {
      const reader = new FileReader();
      reader.addEventListener("load", (progressEvent) => {
        setFile({
          content: progressEvent.target?.result as string,
          name: openedFile.name,
        });
        setIsOpen(true);
        setIsProcessing(false);
      });

      reader.readAsText(openedFile);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <BaseImportButton
        inProgress={isProcessing}
        disabled={!ptsPageStore.ready || isProcessing || isOpen}
        onFilesSelect={handleFileLoad}
      >
        {intl.formatMessage({
          defaultMessage: "Import CSV",
          description: "Import CSV button label",
        })}
      </BaseImportButton>

      <PtsImportModal isOpen={isOpen} onClose={handleClose} file={file} />
    </>
  );
});
