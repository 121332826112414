import axios, { AxiosError, AxiosInstance } from "axios";
import { rootStore } from "@stores/RootStore";

export const apiClient: AxiosInstance = axios.create({
  transformRequest: [
    function (data, headers) {
      const { jwtAuthHeader } = rootStore.authStore;
      headers["Authorization"] = jwtAuthHeader;

      if (data) {
        headers["Content-Type"] = "application/json";
        data = JSON.stringify(data);
      }
      return data;
    },
  ],
});

export const usersApiClient: AxiosInstance = axios.create({
  //TODO move to config
  timeout: 30000,
  transformRequest: [
    function (data, headers) {
      const { bearerAuthHeader } = rootStore.authStore;
      headers["Authorization"] = bearerAuthHeader;

      if (data) {
        headers["Content-Type"] = "application/json";
        data = JSON.stringify(data);
      }
      return data;
    },
  ],
});

const handleError = (error: AxiosError) => {
  if (error.response && [401, 403].includes(error.response.status)) {
    void rootStore.authStore.logout(false);
  }

  return Promise.reject(error);
};

usersApiClient.interceptors.response.use((r) => r, handleError);
apiClient.interceptors.response.use((r) => r, handleError);
