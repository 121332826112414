import React from "react";

import { Icons24CheckCircle, Icons24Remove } from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";

import s from "./style.module.scss";

type Props = {
  active: boolean;
  title?: string;
};

export const ActiveCell = observer(({ active, title }: Props) => {
  return (
    <div className={s.container} title={title}>
      {active ? (
        <>
          <Icons24CheckCircle className={s.green} />
          <span className={s.text}>
            <FormattedMessage defaultMessage="Yes" description="Modal active toggle:yes" />
          </span>
        </>
      ) : (
        <>
          <Icons24Remove className={s.red} />
          <span className={s.text}>
            <FormattedMessage defaultMessage="No" description="Modal active toggle:no" />
          </span>
        </>
      )}
    </div>
  );
});
