import { asValue } from "awilix";
import { AxiosError } from "axios";
import { action, makeAutoObservable, runInAction } from "mobx";
import { defineMessages, MessageDescriptor } from "react-intl";
import { dutyPhonesApi } from "@api/dutyPhoneApi";
import { notificationsApi } from "@api/notificationsApi";
import { ptsApi } from "@api/ptsApi";
import { rootApi } from "@api/rootApi";
import { usersApi } from "@api/userApi";
import { di, getConfig } from "@di";
import { AircraftGroup } from "@models/aircraftGroup";
import { TAirlineData } from "@models/AirlineData";
import { TResourceValue } from "@models/common";
import { UiAlert } from "@models/uiAlert";
import { confirmModal } from "@services/syncedModal";

import { DutyPhonesPageStore } from "../DutyPhonesStore";
import { NotificationsPageStore } from "../NotificationsPageStore";
import { PtsPageStore } from "../PtsPageStore";
import { RootStore } from "../RootStore";
import { UsersPageStore } from "../UsersStore";

const MESSAGES = defineMessages({
  phoneError: {
    defaultMessage: "Please enter a correct phone number",
    description: "Error message when SMS message could not be delivered",
  },
  smsNotDeliveredError: {
    defaultMessage: "SMS message could not be delivered",
    description: "Error message when SMS message could not be delivered",
  },
  smsWelcomeNotDeliveredError: {
    defaultMessage: "SMS welcome message could not be delivered",
    description: "Error message when SMS message could not be delivered",
  },
});

export class HomeStore {
  ready = false;

  usersPageStore: UsersPageStore;
  notificationsPageStore: NotificationsPageStore;
  dutyPhonesPageStore: DutyPhonesPageStore;
  ptsPageStore: PtsPageStore;

  uiAlert: UiAlert | null = null;

  aircrafts: AircraftGroup[] = [];
  airlines: TAirlineData[] = [];

  constructor(public root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.usersPageStore = new UsersPageStore(this, usersApi);
    this.notificationsPageStore = new NotificationsPageStore(this, notificationsApi);
    this.dutyPhonesPageStore = new DutyPhonesPageStore(this, dutyPhonesApi);
    this.ptsPageStore = new PtsPageStore(this, ptsApi);

    void this._init();
  }

  get notificationGroups(): TResourceValue[] {
    const { filterNotificationGroups } = getConfig();
    return this._getFilteredGroups(filterNotificationGroups);
  }

  get ptsGroups(): TResourceValue[] {
    const { filterPtsGroups } = getConfig();
    return this._getFilteredGroups(filterPtsGroups);
  }

  setUIAlert(uiAlert: UiAlert | null) {
    if (!uiAlert?.message) {
      this.uiAlert = null;

      return;
    }

    if (!uiAlert.message.id) {
      reportError(
        `[HomeStore#setUIAlert] Message id is required, but got "${uiAlert.message.id}" instead. Alert will not be shown.`,
      );

      uiAlert.message.id = `ui-alert-id-fallback-${Date.now().toString()}`;
    }

    this.uiAlert = uiAlert;
  }

  // TODO make it inside react app, not as separate react app
  async showConfirmModal(text: MessageDescriptor, values: any) {
    const confirmed = await confirmModal(text, values);
    if (!confirmed) {
      return Promise.reject();
    }
  }

  handlePhoneError(error: unknown, isWelcomeMessage = true) {
    const status = (error as AxiosError).response?.status;
    if (status === 422) {
      this.setUIAlert({
        message: MESSAGES.phoneError,
        type: "error",
      });
      throw error;
    } else if (status === 400) {
      this.setUIAlert({
        message: isWelcomeMessage ? MESSAGES.smsWelcomeNotDeliveredError : MESSAGES.smsNotDeliveredError,
        type: "error",
      });
      throw error;
    }
  }

  async initAircrafts() {
    if (this.aircrafts.length) {
      return this.aircrafts;
    }

    return notificationsApi.getAircrafts().then(action((items) => (this.aircrafts = items)));
  }

  async initAirlines() {
    if (this.airlines.length) {
      return this.airlines;
    }

    return notificationsApi.getAirlines().then(
      action((items) => {
        this.airlines = items;

        return items;
      }),
    );
  }

  private async _init() {
    const token = await rootApi.getFileToken();
    const config = await rootApi.getConfig(token);

    console.debug("Configuration loaded.");

    // @ts-ignore
    const configVersion = config.configVersion;
    const appVersion = process.env.FE_CONFIG_VERSION;

    if (appVersion !== configVersion) {
      console.warn("Frontend configuration version mismatch!", {
        configVersion,
        appVersion,
      });
    }

    di.register({ config: asValue(config) });

    runInAction(() => {
      this.ready = true;
    });
  }

  private _getFilteredGroups(listToFilter: string[] = []): TResourceValue[] {
    const { extractedResources } = this.root.authStore;
    const rolesToExclude = ["admin_api", ...listToFilter];

    return extractedResources.filter((v) => !rolesToExclude.includes(v.roleName));
  }
}
