import React, { useContext, useState } from "react";

import {
  ModalCell,
  ModalSeparator,
  Switch,
  PrimaryButton,
  ModalLabel,
  PillCaption,
  ModalDropdownSelectOption,
  ModalHeader,
  Modal,
  ModalDropdownScreen,
} from "@frontend/assaia-ui";
import { groupBy, sortBy, uniq } from "lodash";
import { observer } from "mobx-react";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as ArrowIcon } from "@assets/arrow.svg";
import { ReactComponent as CirclePlusIcon } from "@assets/circle-plus.svg";
import { ReactComponent as NavRightSVG } from "@assets/nav-right.svg";
import { ModalDropdownButton } from "@components/Common/ModalDropdownButton";
import { ModalSpacer } from "@components/Common/ModalSpacer";
import { getConfig } from "@di";
import { useExtractLocalized } from "@i18n/hooks";
import { ptsDirection, PtsSchedule } from "@models/pts";
import { getReferencePointTitle, getPtsLabel } from "@services/ptsUtils";
import { cn, PtsOperationModalStoreContext } from "@services/react";

import { PtsPreview } from "../PtsPreview";
import modalStyles from "../style.module.scss";

import styles from "./style.module.scss";

type SubcategoriesOptionsProps = {
  disabledSubcategories: string[];
  selectedSubcategoryId: string;
  onSubcategoryChange: (id: string) => void;
};

const SubcategoriesOptions = observer(
  ({ disabledSubcategories, selectedSubcategoryId, onSubcategoryChange }: SubcategoriesOptionsProps) => {
    const { ptsOperationsGroups, operationLabels } = getConfig();
    const ptsGroupings = Object.entries(ptsOperationsGroups).map(([ptsScheduleId, v]) => ({
      ptsScheduleId,
      ...v,
    }));
    const extractLocalized = useExtractLocalized();

    const groupedPtsCategories = sortBy(Object.entries(groupBy(ptsGroupings, (v) => v.ptsCategoryId)), (v) =>
      getPtsLabel(v[0]),
    );

    return (
      <>
        {groupedPtsCategories.map(([categoryId, subcategories]) => {
          return (
            <>
              <ModalSeparator key={categoryId} title={getPtsLabel(categoryId)} />
              {sortBy(subcategories, (v) => getPtsLabel(v.ptsScheduleId)).map(
                ({ ptsScheduleId: id, operationList }) => {
                  const uniqOpLabels = uniq(
                    operationList.map((opName) => extractLocalized(operationLabels[opName], opName)),
                  ).map((opLabel) => <PillCaption key={opLabel}>{opLabel}</PillCaption>);

                  return (
                    <ModalCell
                      key={id}
                      className={cn(styles.internalItem, {
                        [styles.disabled]: disabledSubcategories.includes(id),
                        [styles.selected]: id === selectedSubcategoryId,
                      })}
                      title={getPtsLabel(id)}
                      subtitle={<div className={styles.caption}>{uniqOpLabels}</div>}
                      onClick={() => onSubcategoryChange(id)}
                    />
                  );
                },
              )}
            </>
          );
        })}
      </>
    );
  },
);

const directions: ModalDropdownSelectOption<NonNullable<PtsSchedule["direction"]>>[] = ptsDirection.map((id) => ({
  id,
  title: id,
}));

type Props = {
  onClose: () => void;
};

export const PtsOperationMainScreen = observer(({ onClose }: Props) => {
  const { enablePtsDirection } = getConfig();
  const { pts, ptsScheduleData, onOperationChange, setScreen, onSubmitOperation, onSubcategoryChange } =
    useContext(PtsOperationModalStoreContext);
  const intl = useIntl();

  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id: subcategoryId, direction, uninterruptible } = ptsScheduleData;

  const disabledSubcategories = pts.schedules.filter((v) => v.id !== ptsScheduleData.id).map((v) => v.id) || [];

  const submitHandler = async () => {
    setLoading(true);
    try {
      await onSubmitOperation();
      onClose();
    } catch (e: any) {
      alert(e.toString());
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ModalHeader
        icon={<CirclePlusIcon />}
        onClose={onClose}
        title={intl.formatMessage({
          defaultMessage: "Add operation",
          description: "PTS modal",
        })}
      />

      <PtsPreview startData={ptsScheduleData.start} endData={ptsScheduleData.end} />
      <ModalLabel
        title={intl.formatMessage({
          defaultMessage: "Operation type",
          description: "PTS modal",
        })}
      >
        <ModalDropdownButton value={getPtsLabel(subcategoryId)} onClick={() => setOpened(true)} />

        {opened && (
          <Modal
            className={modalStyles.ptsOperationModal}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <ModalHeader
              title={intl.formatMessage({
                defaultMessage: "Operation type",
                description: "PTS modal",
              })}
              onBack={() => setOpened(false)}
              showBorderBottom={false}
            />
            <SubcategoriesOptions
              selectedSubcategoryId={subcategoryId}
              disabledSubcategories={disabledSubcategories}
              onSubcategoryChange={(id) => {
                onSubcategoryChange(id);
                setOpened(false);
              }}
            />
          </Modal>
        )}
      </ModalLabel>

      {enablePtsDirection && (
        <ModalLabel
          title={intl.formatMessage({
            defaultMessage: "Direction",
            description: "PTS modal",
          })}
        >
          <ModalDropdownScreen
            emptyOption={true}
            title={intl.formatMessage({
              defaultMessage: "Direction",
              description: "PTS modal",
            })}
            options={directions}
            onSelect={(v) => onSubcategoryChange(v[0])}
            selectedIds={direction ? [direction] : []}
            value={direction || ""}
          />
        </ModalLabel>
      )}

      <ModalCell
        title={intl.formatMessage({
          defaultMessage: "Continuous",
          description: "PTS modal",
        })}
        onClick={() => onOperationChange({ uninterruptible: !uninterruptible })}
      >
        <Switch active={uninterruptible} />
      </ModalCell>
      <ModalSeparator
        title={intl.formatMessage({
          defaultMessage: "Configuration",
          description: "PTS modal",
        })}
      />
      <ModalCell
        title={intl.formatMessage({
          defaultMessage: "Operation start",
          description: "PTS modal",
        })}
        subtitle={`${getReferencePointTitle(
          ptsScheduleData.start.referencePoint,
        )}: ${ptsScheduleData.start.idealTime}m`}
        leftIcon={<ArrowIcon />}
        rightIcon={<NavRightSVG />}
        onClick={() => setScreen("start")}
      />

      <ModalCell
        title={intl.formatMessage({
          defaultMessage: "Operation end",
          description: "PTS modal",
        })}
        subtitle={`${getReferencePointTitle(ptsScheduleData.end.referencePoint)}: ${ptsScheduleData.end.idealTime}m`}
        leftIcon={<ArrowIcon style={{ transform: "scaleX(-1)" }} />}
        rightIcon={<NavRightSVG />}
        onClick={() => setScreen("end")}
      />

      <ModalSpacer />

      <PrimaryButton disabled={loading} onClick={submitHandler}>
        <FormattedMessage defaultMessage="Save" description="PTS modal" />
      </PrimaryButton>
    </>
  );
});
