import React, { useContext, useState } from "react";

import { Table } from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { IntlShape, useIntl } from "react-intl";
import { ActiveCell } from "@components/Common/ActiveCell";
import { GroupHeader } from "@components/Common/GroupHeader";
import { TResourceValue } from "@models/common";
import { DutyPhone } from "@models/dutyPhone";
import { cn, HomeContext } from "@services/react";

import styles from "./style.module.scss";

type Props = {
  group: TResourceValue;
};

const getColumns = (intl: IntlShape) => [
  {
    title: intl.formatMessage({
      defaultMessage: "Phone",
      description: "Duty phone table column title",
    }),
    render: (v: DutyPhone) => v.phone,
    width: "40%",
  },
  {
    title: intl.formatMessage({
      defaultMessage: "Description",
      description: "Duty phone table column title",
    }),
    render: (v: DutyPhone) => v.description,
    width: "50%",
  },
  {
    title: intl.formatMessage({
      defaultMessage: "Active",
      description: "Duty phone table column title",
    }),
    getCellClass: (v: DutyPhone) => cn(styles.bold, { green: v.active }),
    render: (v: DutyPhone) => <ActiveCell active={v.active} />,
    width: "10%",
  },
];

export const DutyPhonesGroup = observer(({ group }: Props) => {
  const {
    createDutyPhone,
    deleteDutyPhones,
    editDutyPhone,
    toggleDutyPhonesActive,
    groupedDutyPhones,
    highlightItemId,
    setHighlightItem,
  } = useContext(HomeContext).dutyPhonesPageStore;
  const intl = useIntl();

  const dutyPhones = groupedDutyPhones[group.id] || [];

  const [selectedNotificationsIds, setSelectedNotificationsIds] = useState<string[]>([]);

  const onDelete = () => {
    void deleteDutyPhones(selectedNotificationsIds);
    setSelectedNotificationsIds([]);
  };

  const onToggleActive = (val: boolean) => {
    void toggleDutyPhonesActive(selectedNotificationsIds, val);
    setSelectedNotificationsIds([]);
  };

  return (
    <section className={styles.group}>
      <GroupHeader
        singularLabel={intl.formatMessage({
          defaultMessage: "number",
          description: "duty phones group header",
        })}
        pluralLabel={intl.formatMessage({
          defaultMessage: "numbers",
          description: "duty phones group header",
        })}
        group={group.roleName}
        activeItems={dutyPhones.filter(({ active }) => active).map(({ id }) => id)}
        selectedItems={selectedNotificationsIds}
        onAddItem={() => createDutyPhone(group.id)}
        onDelete={onDelete}
        onToggleActive={onToggleActive}
      />

      <Table
        columns={getColumns(intl)}
        getRowKey={(v) => v.id}
        items={dutyPhones}
        selectedItems={selectedNotificationsIds}
        onSelect={(ids) => setSelectedNotificationsIds(ids)}
        onRowClick={(v: DutyPhone) => editDutyPhone(v)}
        getRowClass={(v: DutyPhone) => (v.active ? "" : "disabled")}
        highlightItemId={highlightItemId}
        onHighlightEnd={() => setHighlightItem(null)}
      />
    </section>
  );
});
