import { useContext, useEffect } from "react";

import { observer } from "mobx-react";
import { Spinner } from "@components/Common/Spinner";
import { HomeContext } from "@services/react";

import { Content } from "../Common/Content";

import { IncidentModal } from "./IncidentModal";
import { NotificationsGroup } from "./NotificationsGroup";

export const NotificationsPage = observer(() => {
  const {
    init,
    ready,
    notificationGroups: groups,
    showNotificationModal,
  } = useContext(HomeContext).notificationsPageStore;

  useEffect(() => {
    init();
  }, []);

  if (!ready) {
    return <Spinner />;
  }

  return (
    <Content>
      {groups.map((group) => (
        <NotificationsGroup group={group} key={group.id} />
      ))}
      {showNotificationModal && <IncidentModal />}
    </Content>
  );
});
