import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  FeatherExternalLink,
  Modal,
  ModalCell,
  ModalHeader,
  ModalHelp,
  ModalLabel,
  ModalSeparator,
  PillCaption,
  PrimaryButton,
} from "@frontend/assaia-ui";
import { AxiosError, isAxiosError } from "axios";
import { observer } from "mobx-react";
import { defineMessages, useIntl } from "react-intl";
import { URLS } from "@services/router";
import { ptsListFromCSV } from "@services/csv/ptsList";
import { Pts, PtsApiSchema } from "@models/pts";
import { HomeContext } from "@services/react";

import s from "./style.module.scss";

type ImportState = "idle" | "uploading" | "success" | "error" | "success-with-errors";

type ParseCsvResult = ReturnType<typeof ptsListFromCSV>;
type UploadPtsResult = { fulfilled: Pts[]; rejected: AxiosError[] };

const extractErrorMessages = (error: unknown, fallback?: string): string[] => {
  if (isAxiosError(error) && error.response?.data?.detail) {
    const { detail } = error.response.data;

    if (typeof detail === "string") {
      return [detail];
    }

    if (Array.isArray(detail)) {
      return detail.map((item: PtsApiSchema["ValidationError"]) => {
        if (item.msg) {
          return [item.loc.join("."), item.msg].join(": ");
        }

        return JSON.stringify(item, null, 2);
      });
    }

    return [JSON.stringify(error.response.data.detail, null, 2)];
  } else if (error instanceof Error && error.message) {
    return [error.message];
  }

  return fallback ? [fallback] : [];
};

const STATUS_MESSAGES = defineMessages<ImportState>({
  idle: {
    defaultMessage: "Please review the data and submit",
    description: "PTS Import modal: idle status",
  },
  uploading: {
    defaultMessage: "Uploading data...",
    description: "PTS Import modal: uploading status",
  },
  success: {
    defaultMessage: "Data uploaded successfully",
    description: "PTS Import modal: success status",
  },
  error: {
    defaultMessage: "An error occurred while parsing or uploading data",
    description: "PTS Import modal: error status",
  },
  "success-with-errors": {
    defaultMessage: "Data uploaded with errors",
    description: "PTS Import modal: success with errors status",
  },
});

const PtsImportPreview = observer(({ parseCsvResult }: { parseCsvResult: null | ParseCsvResult }) => {
  const intl = useIntl();

  if (!parseCsvResult?.parsed?.length) {
    return null;
  }

  return (
    <div className={s.preview}>
      {parseCsvResult.parsed.map((pts, index) => (
        <ModalCell
          key={`pts-import-preview-${index}`}
          hoverable={false}
          active={false}
          showLeftBorder={false}
          className={s.previewItem}
          titleClassName={s.previewItemTitle}
          subtitleClassName={s.previewItemSubtitle}
          subtitle={
            <>
              {pts.description}
              <ul className={s.previewList}>
                <li>
                  {intl.formatMessage({
                    defaultMessage: "active:",
                    description: "PTS Import modal: active",
                  })}
                  &nbsp;
                  <code className={s.previewListValue}>
                    {pts.active
                      ? intl.formatMessage({ defaultMessage: "Yes", description: "PTS Import modal: Yes" })
                      : intl.formatMessage({ defaultMessage: "No", description: "PTS Import modal: No" })}
                  </code>
                </li>
                <li>
                  {intl.formatMessage({
                    defaultMessage: "airline:",
                    description: "PTS Import modal: airline",
                  })}
                  &nbsp;
                  <code className={s.previewListValue}>{pts.airline.id}</code>
                </li>
                <li>
                  {intl.formatMessage({
                    defaultMessage: "schedules:",
                    description: "PTS Import modal: schedules",
                  })}
                  &nbsp;
                  <code className={s.previewListValue}>{pts.schedules.length}</code>
                </li>
              </ul>
            </>
          }
          title={
            <>
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
              <span className={s.previewItemCount}>#{index}</span>

              {pts.id ? (
                <PillCaption
                  className={s.pillUpdate}
                  title={intl.formatMessage({
                    defaultMessage: "Open PTS record in a new tab",
                    description: "PTS Import modal: open PTS record",
                  })}
                  onClick={() => {
                    window.open(`${URLS.PTS}/${pts.id}`, "_blank");
                  }}
                >
                  {pts.id}
                  <FeatherExternalLink
                    className={s.pillUpdateIcon}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                </PillCaption>
              ) : (
                <PillCaption>
                  {intl.formatMessage({
                    defaultMessage: "New",
                    description: "PTS Import modal: new PTS record",
                  })}
                </PillCaption>
              )}
            </>
          }
        />
      ))}
    </div>
  );
});

const ERROR_MESSAGES = defineMessages({
  unknown: {
    defaultMessage: "An unknown error occurred. Please try again later.",
    description: "PTS Import modal: unknown",
  },
  invalidData: {
    defaultMessage: "An unknown error occurred while parsing CSV. Please check the sources and try again.",
    description: "PTS Import modal: unknown error",
  },
  emptyData: {
    defaultMessage: "No data found in the file. Please check the sources and try again.",
    description: "PTS Import modal: unexpected empty PTS Data error",
  },
  uploadError: {
    defaultMessage: "An error occurred while data uploading. Please try again later.",
    description: "PTS Import modal: unknown error",
  },
});

const usePtsImport = (file: { content: string; name: string } | null, onClose: () => void) => {
  const intl = useIntl();
  const {
    ptsPageStore: { uploadPtsFromCSV },
  } = useContext(HomeContext);

  const [importState, setImportState] = useState<ImportState>("idle");
  const [parseCsvResult, setParseCsvResult] = useState<ParseCsvResult | null>(null);
  const [uploadPtsResult, setUploadPtsResult] = useState<UploadPtsResult | null>(null);
  const [errors, setErrors] = useState<string[]>([]);

  const reset = useCallback(() => {
    setImportState("idle");
    setErrors([]);
    setParseCsvResult(null);
    setUploadPtsResult(null);
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (!file) {
      return;
    }

    const parseCSV = (text: string) => {
      let result: null | ParseCsvResult = null;
      const errorMessages: string[] = [];

      const defaultErrorMessage = intl.formatMessage(ERROR_MESSAGES.invalidData);
      try {
        result = ptsListFromCSV(text);
        // Catching incorrect records, transforming them to error messages
        for (const error of result.rejected) {
          errorMessages.push(...extractErrorMessages(error, defaultErrorMessage));
        }
      } catch (error) {
        // Catching common errors during parsing CSV (invalid schema, empty data, etc.)
        errorMessages.push(...extractErrorMessages(error, defaultErrorMessage));
      }

      setParseCsvResult(result);
      setErrors(errorMessages);
      setImportState(errorMessages.length > 0 ? "error" : "idle");
    };

    parseCSV(file.content);
  }, [file, intl]);

  const upload = useCallback(async () => {
    if (!parseCsvResult?.parsed?.length) {
      setErrors([intl.formatMessage(ERROR_MESSAGES.emptyData)]);
      setImportState("error");
      return;
    }

    setImportState("uploading");

    let result: UploadPtsResult | null = null;
    const _errors: string[] = [];

    try {
      result = await uploadPtsFromCSV(parseCsvResult.parsed);
      // Catching request errors, transforming them to error messages
      for (const error of result.rejected) {
        _errors.push(...extractErrorMessages(error, intl.formatMessage(ERROR_MESSAGES.uploadError)));
      }
    } catch (error) {
      // Catching common errors during uploading data (network issues, etc.)
      _errors.push(...extractErrorMessages(error, intl.formatMessage(ERROR_MESSAGES.uploadError)));
    }

    setUploadPtsResult(result);
    setErrors(_errors);

    if (_errors.length === 0) {
      // All requests were successful
      setImportState("success");
    } else if (result && result.fulfilled.length > 0) {
      // Some records were uploaded successfully
      setImportState("success-with-errors");
    } else {
      // All requests failed
      setImportState("error");
    }
  }, [parseCsvResult, uploadPtsFromCSV, intl]);

  const recordCounts = useMemo(() => {
    const result = { new: 0, update: 0, rejected: 0, parsed: 0, total: 0 };
    if (!parseCsvResult) {
      return result;
    }

    result.rejected = parseCsvResult.rejected.length;
    result.parsed = parseCsvResult.parsed.length;
    result.total = result.parsed + result.rejected;

    for (const pts of parseCsvResult.parsed) {
      if ("id" in pts) {
        result.update += 1;
      } else {
        result.new += 1;
      }
    }

    return result;
  }, [parseCsvResult]);

  return {
    importState,
    errors,
    recordCounts,
    uploadPtsResult,
    parseCsvResult,
    upload,
    reset,
  };
};

type Props = {
  onClose: () => void;
  isOpen: boolean;
  file: { content: string; name: string } | null;
};

export const PtsImportModal = observer(({ isOpen, onClose, file }: Props) => {
  const intl = useIntl();
  const { importState, errors, recordCounts, uploadPtsResult, parseCsvResult, upload, reset } = usePtsImport(
    file,
    onClose,
  );

  const footerButton = useMemo(() => {
    switch (importState) {
      case "idle":
        return (
          <PrimaryButton onClick={upload}>
            {intl.formatMessage({ defaultMessage: "Submit", description: "PTS Import modal: submit button" })}
          </PrimaryButton>
        );
      case "uploading":
        return (
          <PrimaryButton disabled>
            {intl.formatMessage({ defaultMessage: "Uploading...", description: "PTS Import modal: uploading button" })}
          </PrimaryButton>
        );
      case "success":
      case "error":
      case "success-with-errors":
        return (
          <PrimaryButton onClick={reset}>
            {intl.formatMessage({ defaultMessage: "Close", description: "PTS Import modal: close button" })}
          </PrimaryButton>
        );
      default:
        return null;
    }
  }, [importState, intl, reset, upload]);

  if (!isOpen || !file) {
    return null;
  }

  const title =
    importState === "error"
      ? intl.formatMessage({ defaultMessage: "Error", description: "PTS Import modal: error title" })
      : intl.formatMessage({ defaultMessage: "Import PTS", description: "PTS Import modal: title" });

  return (
    <Modal
      className={s.ptsImportModal}
      onClose={reset}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
    >
      <ModalHeader title={title} subtitle={file.name} onClose={reset} />

      <div className={s.body}>
        <ModalSeparator title={intl.formatMessage(STATUS_MESSAGES[importState])} />
        <ModalHelp className={s.help}>
          <code className={s.helpContent}>
            {intl.formatMessage(
              {
                defaultMessage:
                  "<strong>CSV parsing result</strong>{br}total parsed: {parsed}{br}rejected: {rejected}{br}new: {new}{br}updated: {update}",
                description: "PTS Import modal: records found",
              },
              {
                ...recordCounts,
                br: <br />,
                strong: (text) => <strong>{text}</strong>,
              },
            )}
          </code>
          <code className={s.helpContent}>
            {intl.formatMessage(
              {
                defaultMessage: "<strong>PTS upload result</strong>{br}uploaded: {uploaded}{br}failed: {failed}",
                description: "PTS Import modal: records found",
              },
              {
                uploaded: uploadPtsResult?.fulfilled.length ?? "-",
                failed: uploadPtsResult ? recordCounts.total - (uploadPtsResult?.fulfilled.length || 0) : "-",
                br: <br />,
                strong: (text) => <strong>{text}</strong>,
              },
            )}
          </code>
        </ModalHelp>

        {errors.length > 0 && (
          <div className={s.errors}>
            {errors.map((message, index) => (
              <ModalLabel title={message} key={`pts-import-error-message-${index}-${message}`} className={s.error} />
            ))}
          </div>
        )}

        {(importState === "idle" || importState === "uploading") && (
          <PtsImportPreview parseCsvResult={parseCsvResult} />
        )}
      </div>

      <div className={s.footer}>{footerButton}</div>
    </Modal>
  );
});
