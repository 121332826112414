import React, { useContext, useMemo } from "react";

import { Modal } from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { ModalFilterScreen } from "@components/Common/ModalFilterScreen";
import { HomeContext, IncidentModalStoreContext } from "@services/react";
import { IncidentModalStore } from "@stores/IncidentModalStore";

import { IncidentAttributeScreen } from "./IncidentAttributeScreen";
import { IncidentMainScreen } from "./IncidentMainScreen";
import { IncidentTimingScreen } from "./IncidentTimingScreen";
import { IncidentTimingScreenSettings } from "./IncidentTimingScreenSettings";

export const IncidentModal = observer(() => {
  const home = useContext(HomeContext);
  const {
    notificationsPageStore: { notificationModalData, newAlertGroup },
  } = home;

  const store = useMemo(
    () =>
      new IncidentModalStore(home, {
        existingIncidentConfig: notificationModalData,
        newAlertGroup,
      }),
    [],
  );

  const { modalScreen, setScreen, closeModal, filtersStore } = store;

  return (
    <IncidentModalStoreContext.Provider value={store}>
      <Modal onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        {modalScreen === "main" && <IncidentMainScreen />}
        {modalScreen === "attribute" && <IncidentAttributeScreen />}
        {modalScreen === "timing" && <IncidentTimingScreen />}
        {modalScreen === "timingOptions" && <IncidentTimingScreenSettings />}
        {modalScreen === "filters" && (
          <ModalFilterScreen onBack={() => setScreen("main")} onClose={closeModal} filtersStore={filtersStore} />
        )}
      </Modal>
    </IncidentModalStoreContext.Provider>
  );
});
