import React, { ComponentProps, ReactNode } from "react";

import { Icons24Alert, Icons24LoopLeftLine, NavItemButton } from "@frontend/assaia-ui";
import { Spinner } from "@components/Common/Spinner";
import { useIntlOutsideProvider } from "@i18n/hooks";
import { cn } from "@services/react";

import s from "./style.module.scss";

type Props = {
  className?: string;
  message?: ReactNode;
  showRefreshBtn?: boolean;
  refreshButtonProps?: ComponentProps<typeof NavItemButton>;
};

export const ErrorMessage = ({ message, showRefreshBtn = true, className, refreshButtonProps = {} }: Props) => {
  const intl = useIntlOutsideProvider();
  const defaultErrorMessage = intl?.formatMessage(
    {
      defaultMessage: "Assaia ApronAI is unavailable. {br} Please try later...",
      description: "Unavailable error message",
    },
    { br: <br /> },
  );

  const errorMessage = message || defaultErrorMessage;

  if (!errorMessage) {
    return <Spinner />;
  }

  return (
    <div className={cn(s.errorMessage, className)}>
      <Icons24Alert className={s.towerIcon} />
      <h1 className={s.title}>{errorMessage}</h1>
      {showRefreshBtn && (
        <NavItemButton
          className={cn(s.refreshBtn, refreshButtonProps?.className)}
          {...refreshButtonProps}
          label={intl?.formatMessage({
            defaultMessage: "Refresh",
            description: "Error Message default refresh button label",
          })}
          icon={<Icons24LoopLeftLine />}
          onClick={() => window.location.reload()}
        />
      )}
    </div>
  );
};
