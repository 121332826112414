import { cloneDeep } from "lodash";
import { action, makeAutoObservable, runInAction } from "mobx";
import { defineMessage } from "react-intl";
import { TPtsApi } from "@api/ptsApi";
import { TResourceValue } from "@models/common";
import { NewPts, Pts } from "@models/pts";
import { customMerge } from "@services/data/common";
import { isNewPts } from "@services/ptsUtils";
import { DeepPartial } from "@services/type-utils";

import { HomeStore } from "../HomeStore";

// TODO: Make separate store for opened PTS page
// and get rid of passing ptsId as argument in methods
export class PtsPageStore {
  ptsList: Pts[] = [];
  ready = false;
  highlightItemId: string | null = null;

  ptsData: Pts | NewPts | null = null;

  ptsMainModalScreen: "main" | "filters" = "main";

  constructor(
    private home: HomeStore,
    private api: TPtsApi,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get ptsTable() {
    return new Map(this.ptsList.map((pts) => [pts.id, pts]));
  }

  getPtsById(ptsId: string | null = "") {
    return this.ptsTable.get(ptsId || "") || null;
  }

  async initPtsList() {
    if (this.ready) {
      return;
    }

    const handleError = (error: Error) => {
      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: something went wrong",
          description: "Error message when PTS could not be loaded",
        }),
        type: "error",
      });
      throw error;
    };

    const promises = [
      this.api.getPtsList().then(this._setPtsList).catch(handleError),
      this.home.initAircrafts().catch(handleError),
    ];

    return Promise.all(promises).then(
      action(() => {
        this.ready = true;
      }),
    );
  }

  onCreateNewPts(airline: TResourceValue) {
    this.ptsData = {
      airline,
      active: true,
      schedules: [],
      description: "",
      filters: {
        excludedAircraftTypes: [],
        requiredAircraftTypes: [],
        requiredTurnaroundLength: null,
        inboundFlightStatus: null,
        outboundFlightStatus: null,
      },
    };
  }

  setSelectedPtsId(id: string | null) {
    this.ptsData = cloneDeep(this.getPtsById(id));
  }

  onPtsChange(data: DeepPartial<Pts>) {
    const pts = this.ptsData;
    if (!pts) {
      return;
    }

    customMerge(pts, data);
  }

  closeModal() {
    this.ptsData = null;
  }

  async onSubmitPts(pts: PtsPageStore["ptsData"]) {
    if (!pts) {
      return;
    }

    const newPts = await this.savePts(pts);

    this._updatePts(newPts);

    return newPts;
  }

  async savePts(data: NewPts | Pts) {
    const pts = await (isNewPts(data) ? this._handleCreatePts(data) : this._handlePatchPts(data));

    pts && this.setHighlightItem(pts.id);

    return pts;
  }

  async deletePtsList(ids: string[]) {
    try {
      await this.api.deletePtsList(ids);

      runInAction(() => {
        this.ptsList = this.ptsList.filter((v) => !ids.includes(v.id));
      });

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "The changes have been applied",
          description: "Success message when PTS has been deleted",
        }),
        type: "info",
      });
    } catch (e) {
      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: changes have not been applied",
          description: "Error message when PTS has not been deleted",
        }),
        type: "error",
      });
      throw e;
    }
  }

  async togglePtsActive(ids: string[], active: boolean) {
    const changedPtsList = this.ptsList.filter(({ id }) => ids.includes(id)).map((pts) => ({ ...pts, active }));

    try {
      await this.api.patchPtsList(changedPtsList);
      changedPtsList.forEach((pts) => this._updatePts(pts));

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "The changes have been applied",
          description: "Success message when PTS has been updated",
        }),
        type: "info",
      });
    } catch (e) {
      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: changes have not been applied",
          description: "Error message when PTS has not been updated",
        }),
        type: "error",
      });
      throw e;
    }
  }

  async copyPtsList(ids: string[]) {
    const listToCopy: NewPts[] = this.ptsList.filter(({ id }) => ids.includes(id)).map(({ id: _, ...rest }) => rest);

    try {
      const newPtsList = await this.api.createPtsList(listToCopy);
      this._updatePtsList(newPtsList);

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "The changes have been applied",
          description: "Success message when PTS has been copied",
        }),
        type: "info",
      });
    } catch (e) {
      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: changes have not been applied",
          description: "Error message when PTS has not been copied",
        }),
        type: "error",
      });
      throw e;
    }
  }

  async uploadPtsFromCSV(list: (Pts | NewPts)[]) {
    try {
      const { fulfilled, rejected } = await this.api.createOrUpdatePtsList(list);

      this._updatePtsList(fulfilled);

      return { fulfilled, rejected };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  setHighlightItem(id: PtsPageStore["highlightItemId"]) {
    this.highlightItemId = id;
  }

  async onRemoveOperations(ptsId: string, ids: string[]) {
    const pts = cloneDeep(this.getPtsById(ptsId));
    if (!pts) {
      return;
    }

    pts.schedules = pts.schedules.filter((ptsSchedule) => !ids.includes(ptsSchedule.id));

    await this.onSubmitPts(pts);
  }

  setPtsMainModalScreen(v: PtsPageStore["ptsMainModalScreen"]) {
    this.ptsMainModalScreen = v;
  }

  private _setPtsList(items: Pts[]) {
    const { ptsGroups } = this.home;
    const allIds = ptsGroups.map((v) => v.id);
    this.ptsList = items.filter((v) => allIds.includes(v.airline.id));
  }

  private async _handleCreatePts(data: NewPts) {
    try {
      const pts = await this.api.createPts(data);

      runInAction(() => this.ptsList.push(pts));

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "PTS has been added",
          description: "Success message when PTS has been added",
        }),
        type: "info",
      });

      return pts;
    } catch (e) {
      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: changes have not been applied",
          description: "Error message when PTS has not been added",
        }),
        type: "error",
      });
      throw e;
    }
  }

  private async _handlePatchPts(ptsData: Pts) {
    try {
      const pts = await this.api.patchPts(ptsData);

      this._updatePts(pts);

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "The changes have been applied",
          description: "Success message when PTS has been updated",
        }),
        type: "info",
      });

      return pts;
    } catch (e) {
      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: changes have not been applied",
          description: "Error message when PTS has not been updated",
        }),
        type: "error",
      });
      throw e;
    }
  }

  private _updatePtsList(list: Pts[]) {
    for (const pts of list) {
      this._updatePts(pts);
    }
  }

  private _updatePts(data: Pts) {
    const idx = this.ptsList.findIndex((n) => n.id === data.id);
    if (idx < 0) {
      this.ptsList.push(data);
      return;
    }

    this.ptsList[idx] = data;
  }
}
