import { useContext, useState } from "react";

import { ModalLabel, Input, ModalHeader, ModalDropdownScreen } from "@frontend/assaia-ui";
import { clamp } from "lodash";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import { ModalSpacer } from "@components/Common/ModalSpacer";
import { getConfig } from "@di";
import { PtsEventScheduleOptions } from "@models/pts";
import { getPtsOperationChartPoints } from "@services/ptsOperationChart/ptsOperationChart";
import { getReferencePointsOptions, getReferencePointTitle, getShortReferencePointTitle } from "@services/ptsUtils";
import { PtsOperationModalStoreContext } from "@services/react";
import { DeepPartial } from "@services/type-utils";

import { PtsOperationChart } from "../PtsOperationChart";
import modalStyles from "../style.module.scss";
import { TimeAxis } from "../Timeaxis";

import s from "./style.module.scss";

type Props = {
  dataKey: "start" | "end";
  onBack: () => void;
};

const parseNullable = (v: string) => {
  const parsed = parseFloat(v);

  if (isNaN(parsed)) {
    return null;
  }

  return parsed || 0;
};

// TODO i18n
const getInputValue = (v: number | null) => (v !== null ? v.toString() : "");

const toPositiveInfinity = (v: number | null) => (v === null ? Number.POSITIVE_INFINITY : v);

const toNegativeInfinity = (v: number | null) => (v === null ? Number.NEGATIVE_INFINITY : v);

const toFiniteOrNull = (v: number | null) => (v !== null && isFinite(v) ? v : null);

export const PtsOperationOptionsScreen = observer(({ dataKey, onBack }: Props) => {
  const { ptsTurnaroundParams } = getConfig();
  const intl = useIntl();
  const { onOperationChange, ptsScheduleData } = useContext(PtsOperationModalStoreContext);
  const [ts, setTs] = useState(0);

  const data = ptsScheduleData[dataKey];
  const { orangeInterval, redInterval, referencePoint } = data;

  const referenceOptions = getReferencePointsOptions(ptsTurnaroundParams);

  const result = getPtsOperationChartPoints(data);
  const referenceValue = getShortReferencePointTitle(referencePoint);

  const onChange = (v: DeepPartial<PtsEventScheduleOptions>) => {
    setTs(Date.now());
    onOperationChange({
      [dataKey]: v,
    });
  };

  const vals = Object.values(result.absValues);
  const b1 = Math.min(...vals) * 60 * 1000;
  const b2 = Math.max(...vals) * 60 * 1000;

  const bounds: [number, number] = b1 !== b2 ? [b1, b2] : [-100 * 60 * 1000, 100 * 60 * 1000];

  return (
    <>
      <ModalHeader
        onBack={onBack}
        title={
          dataKey === "start"
            ? intl.formatMessage({
                defaultMessage: "Operation start",
                description: "PTS operation start title",
              })
            : intl.formatMessage({
                defaultMessage: "Operation end",
                description: "PTS operation end title",
              })
        }
      />

      <TimeAxis bounds={bounds} referenceValue={referenceValue} />
      <PtsOperationChart calculatedPoints={result} className={s.chart} data={data} isStart={dataKey === "start"} />
      <ModalLabel
        title={intl.formatMessage({
          defaultMessage: "Reference time",
          description: "PTS operation reference time",
        })}
      >
        <ModalDropdownScreen
          className={modalStyles.ptsOperationModal}
          title={intl.formatMessage({
            defaultMessage: "Reference time",
            description: "PTS operation reference time",
          })}
          options={referenceOptions}
          onSelect={(v) => onChange({ referencePoint: v[0] })}
          selectedIds={[data.referencePoint]}
          value={getReferencePointTitle(data.referencePoint)}
        />
      </ModalLabel>

      <div className={s.intervalGroup}>
        <ModalLabel
          title={intl.formatMessage({
            defaultMessage: "Very early",
            description: "PTS options",
          })}
        >
          <Input
            key={`${ts} Very early`}
            value={getInputValue(redInterval.start)}
            placeholder="-"
            suffix={intl.formatMessage({
              defaultMessage: "Min",
              description: "PTS options",
            })}
            onChange={(v) => {
              const res = toNegativeInfinity(parseNullable(v));

              const redStart = clamp(res, Number.NEGATIVE_INFINITY, orangeInterval.start ?? data.idealTime);
              const orangeStart = orangeInterval.start ?? redStart;

              onChange({
                redInterval: {
                  start: toFiniteOrNull(redStart),
                },
                orangeInterval: {
                  start: toFiniteOrNull(orangeStart),
                },
              });
            }}
          />
        </ModalLabel>

        <ModalLabel
          title={intl.formatMessage({
            defaultMessage: "Slightly early",
            description: "PTS options",
          })}
        >
          <Input
            key={`${ts} Slightly early`}
            title={intl.formatMessage({
              defaultMessage: "Slightly early",
              description: "PTS options",
            })}
            value={getInputValue(orangeInterval.start)}
            placeholder="-"
            suffix={intl.formatMessage({
              defaultMessage: "Min",
              description: "PTS options",
            })}
            onChange={(v) => {
              const res = toNegativeInfinity(parseNullable(v));

              const orangeStart = clamp(res, redInterval.start ?? Number.NEGATIVE_INFINITY, data.idealTime);

              onChange({
                orangeInterval: {
                  start: toFiniteOrNull(orangeStart),
                },
              });
            }}
          />
        </ModalLabel>
      </div>

      <ModalLabel
        title={intl.formatMessage({
          defaultMessage: "Ideal time",
          description: "PTS options",
        })}
      >
        <Input
          title={intl.formatMessage({
            defaultMessage: "Ideal time",
            description: "PTS options",
          })}
          key={`${ts} Ideal time`}
          value={getInputValue(data.idealTime)}
          placeholder="-"
          suffix={intl.formatMessage({
            defaultMessage: "Min",
            description: "PTS options",
          })}
          onChange={(v) => {
            const idealTime = parseFloat(v) || 0;

            const redStart = Math.min(toNegativeInfinity(data.redInterval.start), idealTime);
            const orangeStart = Math.min(toNegativeInfinity(data.orangeInterval.start), idealTime);
            const orangeEnd = Math.max(toPositiveInfinity(data.orangeInterval.end), idealTime);
            const redEnd = Math.max(toPositiveInfinity(data.redInterval.end), idealTime);

            onChange({
              idealTime,
              orangeInterval: {
                start: toFiniteOrNull(orangeStart),
                end: toFiniteOrNull(orangeEnd),
              },
              redInterval: {
                start: toFiniteOrNull(redStart),
                end: toFiniteOrNull(redEnd),
              },
            });
          }}
        />
      </ModalLabel>

      <div className={s.intervalGroup}>
        <ModalLabel
          title={intl.formatMessage({
            defaultMessage: "Slightly late",
            description: "PTS options",
          })}
        >
          <Input
            title={intl.formatMessage({
              defaultMessage: "Slightly late",
              description: "PTS options",
            })}
            key={`${ts} Slightly late`}
            value={getInputValue(orangeInterval.end)}
            placeholder="-"
            suffix={intl.formatMessage({
              defaultMessage: "Min",
              description: "PTS options",
            })}
            onChange={(v) => {
              const res = toPositiveInfinity(parseNullable(v));

              const orangeEnd = clamp(res, data.idealTime, redInterval.end ?? Number.POSITIVE_INFINITY);

              onChange({
                orangeInterval: {
                  end: toFiniteOrNull(orangeEnd),
                },
              });
            }}
          />
        </ModalLabel>

        <ModalLabel
          title={intl.formatMessage({
            defaultMessage: "Very late",
            description: "PTS options",
          })}
        >
          <Input
            title={intl.formatMessage({
              defaultMessage: "Very late",
              description: "PTS options",
            })}
            key={`${ts} Very late`}
            value={getInputValue(redInterval.end)}
            placeholder="-"
            suffix={intl.formatMessage({
              defaultMessage: "Min",
              description: "PTS options",
            })}
            onChange={(v) => {
              const res = toPositiveInfinity(parseNullable(v));

              const redEnd = clamp(res, orangeInterval.end ?? data.idealTime, Number.POSITIVE_INFINITY);
              const orangeEnd = orangeInterval.end ?? redEnd;

              onChange({
                redInterval: {
                  end: toFiniteOrNull(redEnd),
                },
                orangeInterval: {
                  end: toFiniteOrNull(orangeEnd),
                },
              });
            }}
          />
        </ModalLabel>
      </div>

      <ModalSpacer />
    </>
  );
});
