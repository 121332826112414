import React, { ComponentProps } from "react";

import { PriorityBadge } from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { MessageDescriptor, defineMessages, useIntl } from "react-intl";
import { Severity } from "@models/incidentConfig";

type PriorityBadgeProps = ComponentProps<typeof PriorityBadge>;

type Props = {
  severity: Severity;
};

const SEVERITY_LABELS = defineMessages({
  LOW: {
    defaultMessage: "Low",
    description: "Severity level",
  },
  MEDIUM: {
    defaultMessage: "Medium",
    description: "Severity level",
  },
  HIGH: {
    defaultMessage: "High",
    description: "Severity level",
  },
} as Record<Severity, MessageDescriptor>);

export const SeverityLevel = observer(({ severity }: Props) => {
  const getPriority = (): PriorityBadgeProps["priority"] => {
    if (severity === "LOW") {
      return "grey";
    } else if (severity === "MEDIUM") {
      return "yellow";
    } else {
      return "red";
    }
  };
  const intl = useIntl();

  return (
    <PriorityBadge priority={getPriority()} hoverable={false}>
      {intl.formatMessage(SEVERITY_LABELS[severity])}
    </PriorityBadge>
  );
});
