import { AdminConfig } from "@frontend/configuration";
import { apiClient } from "@api/client";
import { di } from "@di";
import { OpenIdConfig } from "@models/openIdConfig";

export const getApiUrl = (parts = "") => {
  const { authority } = di.resolve("openIdConfig");

  return new URL(authority).origin.replace("id.", "") + `/api/${parts}`;
};
export const getApiUrlV2 = (parts = "") => {
  const { authority } = di.resolve("openIdConfig");

  return new URL(authority).origin.replace("id.", "") + `/api/v2/${parts}`;
};

const getFileToken = async (): Promise<string> => {
  const response = await apiClient.request<string, { data: { token: string } }>({
    url: getApiUrl("account/token"),
  });

  return response.data.token;
};

const getConfig = async (token: string): Promise<AdminConfig> => {
  const response = await fetch(
    "configuration/config.json?" +
      new URLSearchParams({
        token,
      }).toString(),
  );

  if (!response.ok) {
    throw response;
  }

  return response.json();
};

const getInitialOpenIdCreds = async (): Promise<OpenIdConfig> => {
  const response = await fetch("/init.json");
  if (!response.ok) {
    throw response;
  }

  return response.json();
};

export const rootApi = {
  getFileToken,
  getConfig: getConfig,
  getInitialOpenIdCreds,
};

export type TRootApi = typeof rootApi;
