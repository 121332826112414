import React, { useContext, useState } from "react";

import {
  Icons24Trash,
  ModalCell,
  ModalHeader,
  ModalIcon,
  ModalLabel,
  ModalSeparator,
  ModalTextArea,
  PrimaryButton,
  Switch,
} from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as FilterSVG } from "@assets/filter.svg";
import { ReactComponent as NavRightSVG } from "@assets/nav-right.svg";
import { ReactComponent as NotificationSVG } from "@assets/notification.svg";
import { ReactComponent as DotsSVG } from "@assets/three-dots.svg";
import { ReactComponent as TimeSVG } from "@assets/time.svg";
import { ReactComponent as TriggerSVG } from "@assets/trigger.svg";
import { ModalSmallDropdown } from "@components/Common/ModalSmallDropdown";
import { ModalSpacer } from "@components/Common/ModalSpacer";
import { RedStar } from "@components/Common/RedStar";
import { SeverityLevel } from "@components/Common/SeverityLevel";
import { IncidentModalStoreContext } from "@services/react";

import { IncidentAttributeCaption, IncidentFilterCaption } from "../IncidentAttributeCaption";

import styles from "./style.module.scss";

export const IncidentMainScreen = observer(() => {
  const {
    setScreen,
    closeModal,
    toggleActive,
    toggleSeverity,
    setCustomText,
    submit,
    isSubmitting,
    customText,
    active,
    severity,
    attributeSettings,
    isNewIncidentConfig,
    trigger,
    getInvalidFields,
    deleteNotification,
  } = useContext(IncidentModalStoreContext);
  const intl = useIntl();

  const [charCount, setCharCount] = useState(customText.length);

  const buttonDisabled =
    isSubmitting ||
    !!getInvalidFields("attributeSettings", true).length ||
    !!getInvalidFields("startRangeBoundary", true).length ||
    !charCount;

  const attributeSettingsInvalidFields = getInvalidFields("attributeSettings");
  const startRangeBoundaryInvalidFields = getInvalidFields("startRangeBoundary");

  return (
    <>
      <ModalHeader
        onClose={closeModal}
        icon={<NotificationSVG />}
        title={
          isNewIncidentConfig
            ? intl.formatMessage({
                defaultMessage: "Create new alert",
                description: "incident modal:main screen",
              })
            : intl.formatMessage({
                defaultMessage: "Edit alert",
                description: "incident modal:main screen",
              })
        }
      >
        {!isNewIncidentConfig && (
          <ModalSmallDropdown
            trigger={
              // FIXME: `onClick` handler's presence makes clickable appearance of the button
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              <ModalIcon onClick={() => {}}>
                <DotsSVG />
              </ModalIcon>
            }
          >
            <ModalCell
              onClick={deleteNotification}
              title={
                <span className={styles.red}>
                  <FormattedMessage defaultMessage="Delete" description="incident modal:main screen" />
                </span>
              }
              leftIcon={<Icons24Trash className={styles.red} />}
            />
          </ModalSmallDropdown>
        )}
      </ModalHeader>
      <ModalSeparator
        title={intl.formatMessage({
          defaultMessage: "General settings",
          description: "incident modal:main screen",
        })}
      />
      <ModalCell
        title={intl.formatMessage({
          defaultMessage: "Active",
          description: "incident modal:main screen",
        })}
        onClick={toggleActive}
      >
        <Switch active={active} />
      </ModalCell>
      <ModalCell
        title={intl.formatMessage({
          defaultMessage: "Severity level",
          description: "incident modal:main screen",
        })}
        onClick={toggleSeverity}
      >
        <SeverityLevel severity={severity} />
      </ModalCell>
      <ModalSeparator
        title={intl.formatMessage({
          defaultMessage: "Configuration",
          description: "incident modal:main screen",
        })}
      />
      <ModalCell
        title={
          <RedStar>
            <FormattedMessage defaultMessage="Attribute" description="incident modal:main screen" />
          </RedStar>
        }
        leftIcon={<TriggerSVG />}
        rightIcon={<NavRightSVG />}
        onClick={() => setScreen("attribute")}
      >
        <IncidentAttributeCaption
          error={!!attributeSettingsInvalidFields.length}
          data={[
            {
              attribute: attributeSettings.attributeSettings,
              parseTimeShift: false,
              trigger: trigger,
            },
          ]}
        />
      </ModalCell>
      <ModalCell
        title={
          <RedStar>
            <FormattedMessage defaultMessage="Timing" description="incident modal:main screen" />
          </RedStar>
        }
        leftIcon={<TimeSVG />}
        rightIcon={<NavRightSVG />}
        onClick={() => setScreen("timing")}
      >
        <IncidentAttributeCaption
          error={!!startRangeBoundaryInvalidFields.length}
          data={[
            {
              attribute: attributeSettings.startRangeBoundary,
            },
            {
              attribute: attributeSettings.firingPointInTime,
            },
            {
              attribute: attributeSettings.endRangeBoundary,
            },
          ]}
        />
      </ModalCell>
      <ModalCell
        className={styles.filters}
        title={intl.formatMessage({
          defaultMessage: "Filters",
          description: "incident modal:main screen",
        })}
        leftIcon={<FilterSVG />}
        rightIcon={<NavRightSVG />}
        onClick={() => setScreen("filters")}
      >
        <IncidentFilterCaption />
      </ModalCell>
      <ModalLabel
        title={
          <RedStar>
            <FormattedMessage defaultMessage="Notification text" description="incident modal:main screen" />
          </RedStar>
        }
      >
        <ModalTextArea
          disabled={isSubmitting}
          value={customText}
          placeholder={intl.formatMessage({
            defaultMessage: "Set notification text for the alert...",
            description: "incident modal:main screen",
          })}
          onChange={setCustomText}
          maxLength={200}
          onCharCountChange={setCharCount}
        />
      </ModalLabel>
      <ModalSpacer minHeight={"auto"} />
      <PrimaryButton onClick={submit} disabled={buttonDisabled}>
        {isNewIncidentConfig ? (
          <FormattedMessage defaultMessage="create alert" description="incident modal:main screen" />
        ) : (
          <FormattedMessage defaultMessage="edit alert" description="incident modal:main screen" />
        )}
      </PrimaryButton>
    </>
  );
});
