import React, { useContext, useEffect } from "react";

import { observer } from "mobx-react";
import { Content } from "@components/Common/Content";
import { ExportButton } from "@components/Common/ExportButton";
import { PageToolbar } from "@components/Common/PageToolbar";
import { Spinner } from "@components/Common/Spinner";
import { exportDutyPhonesToCSV } from "@services/export";
import { HomeContext } from "@services/react";

import { DutyPhoneModal } from "./DutyPhoneModal";
import { DutyPhonesGroup } from "./DutyPhonesGroup";

import s from "./style.module.scss";

export const DutyPhonesPage = observer(() => {
  const { notificationGroups: groups, dutyPhonesPageStore } = useContext(HomeContext);
  const { initDutyPhones, ready: isReady, dutyPhonesModalData: modalData } = dutyPhonesPageStore;

  useEffect(() => {
    void initDutyPhones();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isReady) {
    return <Spinner />;
  }

  return (
    <Content>
      <div className={s.dutyPhonesPage}>
        <PageToolbar className={s.menu}>
          <PageToolbar.Item align={"right"}>
            <ExportButton onClick={() => exportDutyPhonesToCSV(dutyPhonesPageStore.dutyPhones)} />
          </PageToolbar.Item>
        </PageToolbar>
        {groups.map((v) => (
          <DutyPhonesGroup key={v.id} group={v} />
        ))}
        {modalData && <DutyPhoneModal initialValue={modalData} />}
      </div>
    </Content>
  );
});
