import { createRoot } from "react-dom/client";
import { App } from "@components/App";
import { RootContext } from "@services/react";
import { rootStore } from "@stores/RootStore";
import "./styles/index.scss";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <RootContext.Provider value={rootStore}>
    <App />
  </RootContext.Provider>,
);

window._COMMIT_HASH = process.env.CI_COMMIT_SHA || "none";
