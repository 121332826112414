import React, { PropsWithChildren } from "react";

import { FormattedMessage } from "react-intl";

import s from "./style.module.scss";

export const RedStar = ({ children }: PropsWithChildren<{}>) => (
  <span>
    {children}&nbsp;
    <span className={s.star}>
      <FormattedMessage defaultMessage="*" description="[ignore] red star" />
    </span>
  </span>
);
