import React, { ReactElement, useContext, useMemo } from "react";

import { Icons24CircleFlag, IconWrapper } from "@frontend/airfoil";
import { observer } from "mobx-react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { NavLink, useLocation } from "react-router";
import { IntlContext } from "@components/App/IntlProvider";
import { Dropdown, DropdownItem } from "@components/Common/Dropdown";
import { getConfig } from "@di";
import { Locale } from "@i18n";
import { cn, RootContext } from "@services/react";
import { URLS } from "@services/router";

import { Logo } from "./Logo";
import { NotificationsSearch } from "./Search/notificationsSearch";
import { UserSearch } from "./Search/usersSearch";
import userPic from "./user-pic.png";

import styles from "./style.module.scss";

const LANGUAGE_MESSAGES = defineMessages<Locale>({
  "en-GB": {
    defaultMessage: "English (GB)",
    description: "[ignore] User Menu language switcher English (GB)",
  },
  "en-US": {
    defaultMessage: "English (US)",
    description: "[ignore] User Menu language switcher English (US)",
  },
  "de-DE": {
    defaultMessage: "German",
    description: "[ignore] User Menu language switcher German",
  },
  "ja-JP": {
    defaultMessage: "日本語",
    description: "[ignore] User Menu language switcher Japanese",
  },
});

const LANG_ICONS: Record<Locale, ReactElement> = {
  "en-GB": <Icons24CircleFlag flag={"gb"} />,
  "en-US": <Icons24CircleFlag flag={"us"} />,
  "de-DE": <Icons24CircleFlag flag={"de"} />,
  "ja-JP": <Icons24CircleFlag flag={"jp"} />,
};

//TODO use react-select or remove react-select
export const Header = observer(() => {
  const { user, logout } = useContext(RootContext).authStore;
  const { enableDutyPhones, enablePts } = getConfig();
  const intl = useIntl();
  const { locales, switchLocale, locale: currentLocale } = useContext(IntlContext);
  const location = useLocation();

  const dropdownItems = useMemo<DropdownItem[]>(
    () => [
      ...locales.map((locale) => {
        return {
          key: locale,
          className: cn(styles.userMenuItem, locale === currentLocale && [styles.userMenuItemActive, "active"]),
          title: (
            <>
              {intl.formatMessage(LANGUAGE_MESSAGES[locale])}
              <IconWrapper size={"sm"}>{LANG_ICONS[locale]}</IconWrapper>
            </>
          ),
          onSelect: () => switchLocale(locale),
        };
      }),

      {
        key: "logout",
        className: cn(styles.userMenuItem, "logout-btn"),
        title: (
          <>
            <FormattedMessage defaultMessage="Logout" description="User dropdown logout" />
            <i className="far fa-sign-out" />
          </>
        ),
        onSelect: logout,
      },
    ],
    [currentLocale, locales, logout, switchLocale],
  );

  const title = useMemo(
    () => (
      <>
        {user && (user.profile.full_name || user.profile.email)}
        <i className="far fa-angle-down" />
        <img src={userPic} alt={""} />
      </>
    ),
    [],
  );

  return (
    <>
      <header className={styles.header}>
        <Logo className={styles.logo} />

        {location.pathname.startsWith(URLS.USERS) && <UserSearch />}
        {location.pathname.startsWith(URLS.NOTIFICATIONS) && <NotificationsSearch />}

        <Dropdown className={styles.userDropdown} title={title} items={dropdownItems} />
      </header>
      <nav className={styles.nav}>
        {user?.profile.is_user_admin && (
          <NavLink to={URLS.USERS}>
            <FormattedMessage defaultMessage="Users" description="Users tab title" />
          </NavLink>
        )}
        {user?.profile.is_notification_admin && (
          <NavLink to={URLS.NOTIFICATIONS}>
            <FormattedMessage defaultMessage="Notifications" description="Notifications tab title" />
          </NavLink>
        )}
        {enableDutyPhones && user?.profile.is_user_admin && (
          <NavLink to={URLS.DUTY_PHONES}>
            <FormattedMessage defaultMessage="Duty phones" description="Duty phones tab title" />
          </NavLink>
        )}
        {enablePts && user?.profile.is_pts_admin && (
          <NavLink to={URLS.PTS}>
            <FormattedMessage defaultMessage="PTS" description="PTS tab title" />
          </NavLink>
        )}
      </nav>
    </>
  );
});
