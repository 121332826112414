import React, { HTMLProps } from "react";

import { observer } from "mobx-react";
import { cn } from "@services/react";

import s from "./style.module.scss";

type BaseProps<T = {}> = HTMLProps<HTMLDivElement> & T;

type ToolbarProps = BaseProps<{ withoutMargin?: boolean }>;

const Toolbar = observer(({ children, className, withoutMargin }: ToolbarProps) => (
  <div className={cn(s.pageToolbar, className, { [s.withoutMargin]: withoutMargin })}>{children}</div>
));

type ToolbarItemProps = BaseProps<{ align?: "left" | "right" }>;

const ToolbarItem = observer(({ children, className, align }: ToolbarItemProps) => (
  <div className={cn(s.pageToolbarItem, className, align && s[align])}>{children}</div>
));

export const PageToolbar = Object.assign(Toolbar, { displayName: "PageToolbar", Item: ToolbarItem });
