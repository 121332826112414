import { useCallback, useContext, useEffect, useState } from "react";
import { Locales, Localized } from "@frontend/configuration";
import { createIntl, createIntlCache, IntlShape } from "react-intl";
import { IntlContext } from "@components/App/IntlProvider";

import {
  AVAILABLE_LOCALES,
  DEFAULT_LOCALE,
  getUserLocale,
  handleIntlError,
  handleIntlWarning,
  loadLocaleData,
} from "@i18n";

type LocalizedData<T, U = string> = undefined | null | Partial<Localized<T, U>>;

export const useLocale = () => {
  return useContext(IntlContext);
};

export const useExtractLocalized = () => {
  const { locale } = useLocale();

  // TS overload for better type inference
  function extract<T extends Locales, U = string>(localizedData: LocalizedData<T, U>, fallback: string): string;
  function extract<T extends Locales, U = string>(localizedData: LocalizedData<T, U>): string | undefined;
  function extract<T extends Locales, U = string>(
    localizedData: LocalizedData<T, U>,
    fallback?: string,
  ): U | string | undefined {
    return localizedData?.[locale] ?? fallback;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(extract, [locale]);
};

const cache = createIntlCache();

const initIntl = async (locale = getUserLocale(DEFAULT_LOCALE, AVAILABLE_LOCALES)) => {
  let messages = {};

  try {
    messages = await loadLocaleData(locale);
  } catch (error) {
    reportError(error);
  }

  return createIntl(
    {
      locale,
      messages,
      onError: handleIntlError,
      onWarn: handleIntlWarning,
    },
    cache,
  );
};

export const useIntlOutsideProvider = () => {
  const [intl, setIntl] = useState<IntlShape | null>(null);

  useEffect(() => {
    const fetchIntl = async () => {
      setIntl(await initIntl());
    };

    void fetchIntl();
  }, []);

  return intl;
};
