import React from "react";

import { observer } from "mobx-react";
import { cn } from "@services/react";

import "./style.scss";

type Props = {
  className?: string;
};

export const Spinner = observer(({ className }: Props) => {
  return <div className={cn("spinner", className)} />;
});
