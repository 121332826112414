import React, { useCallback } from "react";

import { observer } from "mobx-react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { cn } from "@services/react";
import { confirmModal } from "@services/syncedModal";

import s from "./style.module.scss";

type Props = {
  group: string;
  selectedItems: string[];
  activeItems: string[];
  singularLabel?: string;
  pluralLabel?: string;
  onAddItem?: () => void;
  onToggleActive?: (val: boolean) => void;
  onDelete: (ids: string[]) => void;
  onCopy?: (ids: string[]) => void;
};

const MESSAGES = defineMessages({
  activateSelected: {
    defaultMessage: "Activate selected {pluralLabel}",
    description: "Group header",
  },
  deactivateSelected: {
    defaultMessage: "Deactivate selected {pluralLabel}",
    description: "Group header",
  },
  cloneSelected: {
    defaultMessage: "Clone selected {pluralLabel}",
    description: "Group header",
  },
  delete: {
    defaultMessage: "Delete",
    description: "Delete",
  },
  clone: {
    defaultMessage: "Clone",
    description: "Clone",
  },
  deactivate: {
    defaultMessage: "Deactivate",
    description: "Deactivate",
  },
  activate: {
    defaultMessage: "Activate",
    description: "Activate",
  },
  add: {
    defaultMessage: "Add",
    description: "Add",
  },
});

export const GroupHeader = observer(
  ({
    group,
    onAddItem,
    onToggleActive,
    onDelete,
    selectedItems,
    activeItems,
    singularLabel = "item",
    pluralLabel = "items",
    onCopy,
  }: Props) => {
    const intl = useIntl();

    const handleDelete = useCallback(async () => {
      const confirmed = await confirmModal();
      if (!confirmed) {
        return;
      }

      onDelete(selectedItems);
    }, [selectedItems, onDelete]);

    const handleToggleActive = useCallback(
      async (val: boolean) => {
        const confirmed = await confirmModal(val ? MESSAGES.activateSelected : MESSAGES.deactivateSelected, {
          pluralLabel,
        });
        if (!confirmed) {
          return;
        }

        onToggleActive && onToggleActive(val);
      },
      [onToggleActive, pluralLabel],
    );

    const handleClone = useCallback(async () => {
      const confirmed = await confirmModal(MESSAGES.cloneSelected, { pluralLabel });
      if (!confirmed) {
        return;
      }

      onCopy && onCopy(selectedItems);
    }, [pluralLabel, onCopy, selectedItems]);

    const { activeSelected, inactiveSelected } = selectedItems.reduce(
      (acc, item) => {
        if (activeItems.includes(item)) {
          acc.inactiveSelected = false;
        } else {
          acc.activeSelected = false;
        }

        return acc;
      },
      { activeSelected: true, inactiveSelected: true },
    );

    return (
      <div className={s.groupHeader}>
        <label>{group}</label>

        {!!selectedItems.length && (
          <div className={s.selectedItems}>
            <span>
              <span className={s.green}>
                <FormattedMessage
                  defaultMessage="{count, plural, one {# {singularLabel}} other {# {pluralLabel}}} selected"
                  description="group header selected"
                  values={{
                    count: selectedItems.length,
                    singularLabel: singularLabel,
                    pluralLabel: pluralLabel,
                  }}
                />
              </span>
            </span>

            <a onClick={handleDelete} className={s.deleteBtn} title={intl.formatMessage(MESSAGES.delete)}>
              <i className={"fas fa-trash"} />
            </a>

            {onCopy && (
              <a onClick={handleClone} className={s.toggleCloneBtn} title={intl.formatMessage(MESSAGES.clone)}>
                <i className={"fas fa-copy"} />
              </a>
            )}

            {onToggleActive && activeSelected && (
              <a
                onClick={() => handleToggleActive(false)}
                className={cn(s.toggleActiveBtn, s.active)}
                title={intl.formatMessage(MESSAGES.deactivate)}
              >
                <i className="fas fa-power-off" />
              </a>
            )}

            {onToggleActive && inactiveSelected && (
              <a
                onClick={() => handleToggleActive(true)}
                className={s.toggleActiveBtn}
                title={intl.formatMessage(MESSAGES.activate)}
              >
                <i className="fas fa-power-off" />
              </a>
            )}
          </div>
        )}

        {onAddItem && (
          <a onClick={onAddItem} className={s.addItem} title={intl.formatMessage(MESSAGES.add)}>
            <i className={"fas fa-plus"} />
            <FormattedMessage
              defaultMessage="Add {singularLabel}"
              values={{ singularLabel }}
              description="group header add"
            />
          </a>
        )}
      </div>
    );
  },
);
