import React from "react";

import { observer } from "mobx-react";
import { PageToolbar } from "@components/Common/PageToolbar";

import { PtsExportButton } from "../ExportButton";
import { PtsImportButton } from "../ImportButton";

export const Toolbar = observer(() => (
  <PageToolbar>
    <PageToolbar.Item align={"right"}>
      <PtsImportButton />
      <PtsExportButton />
    </PageToolbar.Item>
  </PageToolbar>
));
