import { AdminConfig } from "@frontend/configuration";
import { createContainer } from "awilix";
import { Locale, Locales } from "@i18n/types";
import { OpenIdConfig } from "@models/openIdConfig";

export type DIProps = {
  config: AdminConfig;
  openIdConfig: OpenIdConfig;
  locale: Locale;
  locales: Locales;
};

//TODO use more DI

export const di = createContainer<DIProps>({ injectionMode: "PROXY" });
// @ts-ignore
window._di = di;

export function getConfig() {
  return di.resolve("config");
}

//TODO review al usages and try to reduce direct usages and use more injections
