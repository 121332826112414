import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import { defineMessage, useIntl } from "react-intl";
import { getLatestSchemaVersion } from "@services/csv/schema";

import { ExportButton as BaseExportButton } from "@components/Common/ExportButton";
import { ptsListToCSV } from "@services/csv/ptsList";
import { exportFile } from "@services/export";
import { HomeContext } from "@services/react";

export const PtsExportButton = observer(() => {
  const intl = useIntl();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const {
    setUIAlert,
    ptsPageStore: { ready, ptsList },
  } = useContext(HomeContext);

  const isDisabled = !ready || isProcessing || !ptsList.length;

  // TODO: create store method
  const handleExport = () => {
    if (isDisabled) {
      return;
    }

    try {
      setIsProcessing(true);
      const _pts = [
        {
          schedules: [
            {
              id: "pts_schedule_acu_connected",
              opName: "",
              direction: "both",
              start: {
                referencePoint: "aircraft_start_ts",
                idealTime: 0,
                orangeInterval: {
                  start: null,
                  end: 60,
                },
                redInterval: {
                  start: null,
                  end: 222,
                },
              },
              end: {
                referencePoint: "sobt",
                idealTime: 11,
                orangeInterval: {
                  start: null,
                  end: 60,
                },
                redInterval: {
                  start: null,
                  end: 122,
                },
              },
              uninterruptible: false,
            },
          ],
          filters: {
            requiredTurnaroundLength: "SHORT",
            requiredAircraftTypes: ["319", "31C", "31J", "3HF"],
            excludedAircraftTypes: [""],
            inboundFlightStatus: "EMPTY",
            outboundFlightStatus: "SET",
          },
          airline: {
            id: "SEA::Airport",
            roleName: "SEA",
          },
          description: "test",
          active: true,
          id: "6707fed3bc6b6f45f6f8646d",
        },
        {
          schedules: [
            {
              id: "pts_schedule_acu_connected",
              opName: "",
              direction: "both",
              start: {
                referencePoint: "aircraft_start_ts",
                idealTime: 0,
                orangeInterval: {
                  start: null,
                  end: 60,
                },
                redInterval: {
                  start: null,
                  end: 120,
                },
              },
              end: {
                referencePoint: "sobt",
                idealTime: 0,
                orangeInterval: {
                  start: null,
                  end: 60,
                },
                redInterval: {
                  start: null,
                  end: 120,
                },
              },
              uninterruptible: false,
            },
            {
              id: "pts_schedule_aircraft_on_stand",
              opName: "",
              direction: "both",
              start: {
                referencePoint: "pobt",
                idealTime: 0,
                orangeInterval: {
                  start: null,
                  end: 60,
                },
                redInterval: {
                  start: null,
                  end: 120,
                },
              },
              end: {
                referencePoint: "sobt",
                idealTime: 120,
                orangeInterval: {
                  start: 120,
                  end: 120,
                },
                redInterval: {
                  start: 60,
                  end: 120,
                },
              },
              uninterruptible: false,
            },
            {
              id: "pts_schedule_aircraft_parked",
              opName: "",
              direction: "both",
              start: {
                referencePoint: "aircraft_start_ts",
                idealTime: 0,
                orangeInterval: {
                  start: null,
                  end: 60,
                },
                redInterval: {
                  start: null,
                  end: 120,
                },
              },
              end: {
                referencePoint: "sobt",
                idealTime: 0,
                orangeInterval: {
                  start: null,
                  end: 60,
                },
                redInterval: {
                  start: null,
                  end: 120,
                },
              },
              uninterruptible: false,
            },
          ],
          filters: {
            requiredTurnaroundLength: "LONG",
            requiredAircraftTypes: ["319", "31C", "31J", "3HF", "223"],
            excludedAircraftTypes: [""],
            inboundFlightStatus: "EMPTY",
            outboundFlightStatus: "SET",
          },
          airline: {
            id: "ASA::Airline",
            roleName: "ASA",
          },
          description: "updated from CSV",
          active: true,
          id: "670688d6bc6b6f45f6f8646c",
        },
      ] as any;
      const csv = ptsListToCSV(_pts, getLatestSchemaVersion());

      exportFile("pts-list.csv", csv);
    } catch (error) {
      console.error(error);
      setUIAlert({
        message: defineMessage({
          defaultMessage: "Error exporting CSV",
          description: "Error exporting CSV alert message",
        }),
        type: "error",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <BaseExportButton
      disabled={isDisabled}
      onClick={handleExport}
      title={
        ptsList.length
          ? intl.formatMessage({
              defaultMessage: "Export CSV",
              description: "Export CSV button title",
            })
          : intl.formatMessage({
              defaultMessage: "No data to export",
              description: "Export CSV button title",
            })
      }
    >
      {intl.formatMessage({
        defaultMessage: "Export CSV",
        description: "Export CSV button label",
      })}
    </BaseExportButton>
  );
});
