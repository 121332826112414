import React, { ComponentProps } from "react";

import { DropdownButton } from "@frontend/assaia-ui";
import { observer } from "mobx-react";

export type Props = ComponentProps<typeof DropdownButton>;

export const ModalDropdownButton = observer(({ children, ...rest }: Props) => {
  return (
    <DropdownButton {...rest} arrow="right">
      {children}
    </DropdownButton>
  );
});
