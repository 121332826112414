import { usersApiClient } from "@api/client";
import { camelCaseKeys, snakeCaseKeys } from "@api/parsers";
import { di } from "@di";
import { DutyPhone, NewDutyPhone } from "@models/dutyPhone";

const dutyPhonesApiUrl = () => {
  const { authority } = di.resolve("openIdConfig");
  return new URL(authority).origin + "/api/";
};

const getDutyPhones = () =>
  usersApiClient
    .get<DutyPhone[]>(dutyPhonesApiUrl() + "duty_phones/")
    .then((r) => r.data.map(camelCaseKeys) as DutyPhone[]);

const createDutyPhone = (data: NewDutyPhone): Promise<DutyPhone> =>
  usersApiClient
    .post<DutyPhone>(dutyPhonesApiUrl() + "duty_phones/", snakeCaseKeys(data))
    .then((res) => camelCaseKeys(res.data));

const putDutyPhone = (data: DutyPhone) =>
  usersApiClient
    .put<DutyPhone>(dutyPhonesApiUrl() + "duty_phones/" + data.id, snakeCaseKeys(data))
    .then((res) => camelCaseKeys(res.data));

const deleteDutyPhones = (ids: string[]) => {
  return Promise.all(ids.map((id) => usersApiClient.delete(dutyPhonesApiUrl() + `duty_phones/${id}`)));
};

const toggleDutyPhonesActive = (data: DutyPhone[]) => {
  return Promise.all(data.map((dutyPhone) => putDutyPhone(dutyPhone)));
};

export const dutyPhonesApi = {
  getDutyPhones,
  createDutyPhone,
  putDutyPhone,
  deleteDutyPhones,
  toggleDutyPhonesActive,
};

export type TDutyPhonesApi = typeof dutyPhonesApi;
