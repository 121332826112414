import React, { PropsWithChildren } from "react";

import { Popup } from "@frontend/assaia-ui";
import { observer } from "mobx-react";

import s from "./style.module.scss";

type Props = PropsWithChildren<{
  trigger: React.ReactElement;
}>;

export const ModalSmallDropdown = observer(({ children, trigger }: Props) => (
  <Popup
    closeOnPopupClick
    popupHorizontalAlign="right"
    popupContent={<div className={s.dropdownContent}>{children}</div>}
  >
    {trigger}
  </Popup>
));
