import React, { PropsWithChildren } from "react";
import { observer } from "mobx-react";

import styles from "./style.module.scss";

type Props = PropsWithChildren<{
  subtitle?: React.ReactNode;
}>;

export const TableColumnContent = observer(({ children, subtitle }: Props) => {
  return (
    <div className={styles.columnContent}>
      <span className={styles.title}>{children}</span>
      {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
    </div>
  );
});
