import React, { useContext } from "react";

import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import { HomeContext } from "@services/react";

import "./style.scss";

export const NotificationsSearch = observer(() => {
  const { searchQuery, setNotificationsQuery } = useContext(HomeContext).notificationsPageStore;
  const intl = useIntl();

  return (
    <div className={"search"}>
      <input
        placeholder={intl.formatMessage({
          defaultMessage: "Search notifications (min 2 symbols)",
          description: "Search notifications input placeholder",
        })}
        value={searchQuery}
        onChange={(ev) => setNotificationsQuery(ev.target.value)}
      />
      <i className={"fas fa-search"} />
    </div>
  );
});
