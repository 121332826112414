import { ComponentProps } from "react";

import { ModalSpacer as BaseModalSpacer } from "@frontend/assaia-ui";

type Props = ComponentProps<typeof BaseModalSpacer> & {
  minHeight?: string;
};

export const ModalSpacer = ({ minHeight = "32px", ...restProps }: Props) => (
  <BaseModalSpacer style={{ minHeight: minHeight || "auto" }} {...restProps} />
);
