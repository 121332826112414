export const isFunction = <T>(
  value: T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): value is T extends (...args: any) => any ? T : never => {
  return typeof value === "function";
};

export const isFiniteNumber = (value: unknown): value is number => Number.isFinite(value);

// Converts passed string (including string literals) to lowercase.
// Returns fallback value if the input is not a string.
export function toLowerCase<T, F>(value: T, fallback?: F): T extends string ? Lowercase<T> : F;
export function toLowerCase(value: unknown, fallback = undefined): string | undefined {
  if (typeof value === "string") {
    return value.toLowerCase();
  }

  return fallback;
}

// Converts passed string (including string literals) to uppercase.
// Returns fallback value if the input is not a string.
export function toUpperCase<T, F>(value: T, fallback?: F): T extends string ? Uppercase<T> : F;
export function toUpperCase(value: unknown, fallback = undefined): string | undefined {
  if (typeof value === "string") {
    return value.toUpperCase();
  }

  return fallback;
}
