import { TResourceValue } from "@models/common";
import { RequireSome } from "@services/type-utils";

import { components } from "./apiSpecs/ptsApiSpec";
import { IncidentConfig } from "./incidentConfig";

export const ptsDirection = ["in", "out", "both"] as const;

export type PtsInterval = {
  start: number | null;
  end: number | null;
};

export type PtsEventScheduleOptions = {
  idealTime: number;
  referencePoint: string;
  orangeInterval: PtsInterval;
  redInterval: PtsInterval;
};

export type PtsSchedule = {
  id: string;
  direction?: (typeof ptsDirection)[number];
  start: PtsEventScheduleOptions;
  end: PtsEventScheduleOptions;
  uninterruptible: boolean;

  // Deprecated: remove after migration
  opName: string;
};

export type NewPtsSchedule = Omit<PtsSchedule, "opName">;

export type PtsBase = {
  schedules: PtsSchedule[];
  filters: {
    requiredAircraftTypes: string[];
    excludedAircraftTypes: string[];
    requiredTurnaroundLength: IncidentConfig["requiredTurnaroundLength"];
    inboundFlightStatus: IncidentConfig["inboundFlightStatus"];
    outboundFlightStatus: IncidentConfig["outboundFlightStatus"];
  };
  airline: TResourceValue;
  active: boolean;
  description: string;
};

export type NewPts = RequireSome<PtsBase, "airline" | "active"> & { id?: never };

export type Pts = PtsBase & { id: string };

export type PartialPts = Partial<Pts>;

export type PtsApiSchema = components["schemas"];
