import React, { HTMLProps } from "react";

import { observer } from "mobx-react";
import { cn } from "@services/react";

import s from "./style.module.scss";

export const Content = observer(({ className, ...props }: HTMLProps<HTMLDivElement>) => (
  <div {...props} className={cn(s.content, className)} />
));
