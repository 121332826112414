import { cloneDeep } from "lodash";
import { apiClient } from "@api/client";
import { getApiUrl } from "@api/rootApi";
import { AircraftGroup } from "@models/aircraftGroup";
import {
  IncidentConfig,
  NewIncidentConfig,
  SafetyAlertIncidentConfig,
  SafetyEventIncidentConfig,
  UnifiedIncidentConfig,
} from "@models/incidentConfig";

import { parseAircraftGroups, parseIncidentConfig, snakeCaseKeys } from "./parsers";

const snakeCaseKeysIncidentConfig = ({
  requiredAirlineIcaoAndIata,
  excludedAirlineIcaoAndIata,
  ...v
}: Partial<IncidentConfig | NewIncidentConfig>) => {
  const result = snakeCaseKeys(v);

  result.required_airlines = requiredAirlineIcaoAndIata;
  result.excluded_airlines = excludedAirlineIcaoAndIata;

  result.data.strategy = result.data.type;

  if ("incident_type" in result.data) {
    delete result.data.incident_type;
  }
  if ("type" in result.data) {
    result.data.strategy = result.data.type;
    delete result.data.type;
  }

  if ("detected_attributes" in result.data) {
    result.data.detected_attributes = result.data.detected_attributes.map((v: any) => snakeCaseKeys(v));
  }

  result.group = v.group?.id;

  console.log(
    "snakeCaseKeysIncidentConfig",
    cloneDeep({
      requiredAirlineIcaoAndIata,
      excludedAirlineIcaoAndIata,
      v,
      result,
    }),
  );

  return result;
};

const getNotifications = () =>
  apiClient.get<IncidentConfig[]>(getApiUrl("incident_configs/")).then((r) => {
    const rawConfigs = r.data;
    const result: {
      unifiedConfigs: UnifiedIncidentConfig[];
      safetyAlertConfigs: SafetyAlertIncidentConfig[];
      safetyEventConfigs: SafetyEventIncidentConfig[];
    } = {
      unifiedConfigs: [],
      safetyAlertConfigs: [],
      safetyEventConfigs: [],
    };

    rawConfigs.forEach((raw) => {
      const parsedData = parseIncidentConfig(raw);
      const data = parsedData.data;
      if (data.incidentType === "unified") {
        result.unifiedConfigs.push({
          ...parsedData,
          data,
        });
      } else if (data.incidentType === "safety-alert") {
        result.safetyAlertConfigs.push({
          ...parsedData,
          data,
        });
      } else if (data.incidentType === "safety-event") {
        result.safetyEventConfigs.push({
          ...parsedData,
          data,
        });
      }
    });

    return result;
  });

const saveNewNotification = (data: NewIncidentConfig) =>
  apiClient
    .post<IncidentConfig>(getApiUrl("incident_configs/"), snakeCaseKeysIncidentConfig(data))
    .then((res) => parseIncidentConfig(res.data));

const patchNotification = (id: string, data: Partial<NewIncidentConfig>) =>
  apiClient
    .patch<IncidentConfig>(getApiUrl("incident_configs/") + id, snakeCaseKeysIncidentConfig(data))
    .then((res) => parseIncidentConfig(res.data));

const deleteNotifications = (ids: string[]) => {
  const query = ids.map((id) => "id=" + id).join("&");
  return apiClient.delete(getApiUrl("incident_configs/?") + query);
};

const toggleNotificationsActive = (ids: string[], active: boolean) => {
  const query = ids.map((id) => "id=" + id).join("&");
  return apiClient.patch(getApiUrl("incident_configs/?") + query, { active });
};

const getStands = (): Promise<string[]> => {
  return apiClient.get(getApiUrl("stands/")).then((r) => r.data.map((s: any) => s.id));
};

const getAircrafts = (): Promise<AircraftGroup[]> => {
  return apiClient.get(getApiUrl("flights/aircraft_groups")).then((r) => parseAircraftGroups(r.data));
};

const getAirlines = () => {
  return apiClient.get(getApiUrl("flights/airlines")).then((r) =>
    r.data.map((v: any) => ({
      ...v,
      id: v.id.toString(),
    })),
  );
};

export const notificationsApi = {
  getNotifications,
  saveNewNotification,
  patchNotification,
  deleteNotifications,
  toggleNotificationsActive,
  getStands,
  getAircrafts,
  getAirlines,
};

export type TNotificationsApi = typeof notificationsApi;
