import { keyBy } from "lodash";
import { getConfig } from "@di";
import { DutyPhone } from "@models/dutyPhone";

import { Role } from "../models/role";
import { User } from "../models/user";

declare global {}

export function exportFile(filename: string, text: string) {
  const element = document.createElement("a");
  element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
  element.setAttribute("download", filename.replace(/\s/g, "_"));
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function exportUsersToCSV(users: User[], roles: Role[]) {
  const rolesMap = keyBy(roles, "id");

  let columns: [string, (u: User) => string][] = [
    ["Email", (u: User) => u.email],
    ["Full name", (u: User) => u.profile.full_name || ""],
    ["Company", (u: User) => u.profile.company || ""],
    ["Phone", (u: User) => u.phone?.phone || ""],
    ["Roles", (u: User) => u.roles.map((r) => rolesMap[r]?.name).join(",")],
  ];

  const { enableDutyPhones } = getConfig();
  if (!enableDutyPhones) {
    const [user, name, company, phone, roles] = columns;
    columns = [user, name, company, roles];
  }

  let text = columns.map((c) => c[0]).join(";") + "\n";
  users.forEach((u) => {
    text += columns.map((c) => c[1](u)).join(";") + "\n";
  });

  exportFile("users.csv", text);
}

export function exportDutyPhonesToCSV(phones: DutyPhone[]) {
  const columns: [string, (p: DutyPhone) => string][] = [
    ["Phone", (p: DutyPhone) => p.phone],
    ["Group", (p: DutyPhone) => p.group],
    ["Active", (p: DutyPhone) => (p.active ? "Yes" : "No")],
  ];

  let text = columns.map((c) => c[0]).join(";") + "\n";
  phones.forEach((p) => {
    text += columns.map((c) => c[1](p)).join(";") + "\n";
  });

  exportFile("phones.csv", text);
}
