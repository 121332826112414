import React, { useContext } from "react";

import { ModalHeader, ModalCell } from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as NavRightSVG } from "@assets/nav-right.svg";
import { ReactComponent as TimeSVG } from "@assets/time.svg";
import { ModalSpacer } from "@components/Common/ModalSpacer";
import { RedStar } from "@components/Common/RedStar";
import { IncidentModalStoreContext } from "@services/react";

import { IncidentAttributeCaption } from "../IncidentAttributeCaption";

export const IncidentTimingScreen = observer(() => {
  const { closeModal, setScreen, setSelectedAttribute, attributeSettings, getInvalidFields } =
    useContext(IncidentModalStoreContext);
  const intl = useIntl();

  const startRangeBoundaryInvalidFields = getInvalidFields("startRangeBoundary");

  return (
    <>
      <ModalHeader
        showBorderBottom={true}
        onBack={() => setScreen("main")}
        onClose={closeModal}
        icon={<TimeSVG />}
        title={intl.formatMessage({
          defaultMessage: "Timing",
          description: "incident modal:timing screen",
        })}
      />
      <ModalCell
        title={
          <RedStar>
            <FormattedMessage defaultMessage="Start monitoring" description="incident modal:timing screen" />
          </RedStar>
        }
        rightIcon={<NavRightSVG />}
        onClick={() => {
          setSelectedAttribute("startRangeBoundary");
          setScreen("timingOptions");
        }}
      >
        <IncidentAttributeCaption
          error={!!startRangeBoundaryInvalidFields.length}
          data={[
            {
              attribute: attributeSettings.startRangeBoundary,
            },
          ]}
        />
      </ModalCell>
      <ModalCell
        title={intl.formatMessage({
          defaultMessage: "Firing point",
          description: "incident modal:timing screen",
        })}
        rightIcon={<NavRightSVG />}
        onClick={() => {
          setSelectedAttribute("firingPointInTime");
          setScreen("timingOptions");
        }}
      >
        <IncidentAttributeCaption
          data={[
            {
              attribute: attributeSettings.firingPointInTime,
            },
          ]}
        />
      </ModalCell>
      <ModalCell
        title={intl.formatMessage({
          defaultMessage: "Stop monitoring",
          description: "incident modal:timing screen",
        })}
        rightIcon={<NavRightSVG />}
        onClick={() => {
          setSelectedAttribute("endRangeBoundary");
          setScreen("timingOptions");
        }}
      >
        <IncidentAttributeCaption
          data={[
            {
              attribute: attributeSettings.endRangeBoundary,
            },
          ]}
        />
      </ModalCell>
      <ModalSpacer />
    </>
  );
});
