import { AxiosError, AxiosResponse } from "axios";
import { partition, uniqBy } from "lodash";
import { apiClient as ptsApiClient } from "@api/client";
import { camelCaseKeys, snakeCaseKeys } from "@api/parsers";
import { getApiUrlV2 } from "@api/rootApi";
import { NewPts, PartialPts, Pts, PtsApiSchema, PtsSchedule } from "@models/pts";
import { formatNotificationGroupLabel } from "@services/data/incidents";
import { isNewPts } from "@services/ptsUtils";
import { toLowerCase, toUpperCase } from "@services/utils";

const getApiUrl = (parts = "") => getApiUrlV2(`pts/${parts}`);

type PTSApi = PtsApiSchema["PTS"];
type PTSApiUpdate = PtsApiSchema["PTSUpdate"];
type PTSApiCreate = PtsApiSchema["PTSCreate"];

const normalizePts = (data: PTSApi): Pts => {
  const result = camelCaseKeys(data);

  result.filters = {
    ...result.filters,
    requiredTurnaroundLength: toUpperCase(result.filters.requiredTurnaroundLength),
    inboundFlightStatus: toUpperCase(result.filters.inboundFlightStatus),
    outboundFlightStatus: toUpperCase(result.filters.outboundFlightStatus),
  };

  result.schedules = (data.schedules || [])?.map((schedule) => {
    const newValue = camelCaseKeys(schedule);

    if (!newValue.start.referencePoint) {
      newValue.start.referencePoint = "sobt";
    }

    if (!newValue.end.referencePoint) {
      newValue.end.referencePoint = "sobt";
    }

    return newValue as PtsSchedule;
  });

  result.airline = {
    id: data.airline,
    roleName: formatNotificationGroupLabel(data.airline),
  };

  return result;
};

const normalizePtsResponse = ({ data }: AxiosResponse<PTSApi>): Pts => normalizePts(data);

// Returns normalized PTS data to create or update (partial) PTS
// Does not support full PTS and should not be used for that
const denormalizePts = <P extends PartialPts | NewPts>(ptsData: P): PTSApiCreate | PTSApiUpdate => {
  const { schedules, filters, active, airline, description } = ptsData;

  const result: PTSApiCreate | PTSApiUpdate = {
    active,
    airline: airline?.id,
    description,
  };

  if (filters) {
    result.filters = {
      excluded_aircraft_types: filters.excludedAircraftTypes,
      required_aircraft_types: filters.requiredAircraftTypes,
      inbound_flight_status: toLowerCase(filters.inboundFlightStatus),
      outbound_flight_status: toLowerCase(filters.outboundFlightStatus),
      required_turnaround_length: toLowerCase(filters.requiredTurnaroundLength),
    };
  }

  if (schedules) {
    result.schedules = schedules.map(({ opName, start, end, direction, ...rest }) => ({
      ...rest,
      direction: direction || undefined,
      op_name: "",
      start: snakeCaseKeys(start),
      end: snakeCaseKeys(end),
    }));
    result.schedules = uniqBy(result.schedules, "id");
  }

  return result;
};

const getPtsList = () =>
  ptsApiClient.get<Pts[], AxiosResponse<PTSApi[]>, PTSApi[]>(getApiUrl()).then(({ data }) => {
    return data.map(normalizePts).map<Pts>((pts) => ({
      ...pts,
      schedules: uniqBy(pts.schedules, "id"),
    }));
  });

const getPtsByGroup = (group: string) =>
  ptsApiClient.get<Pts, AxiosResponse<PTSApi>>(getApiUrl(group)).then(normalizePtsResponse);

const createPts = (ptsData: NewPts): Promise<Pts> =>
  ptsApiClient
    .post<PTSApiCreate, AxiosResponse<PTSApi>>(getApiUrl(), denormalizePts(ptsData))
    .then(normalizePtsResponse);

const patchPts = ({ id, ...ptsData }: PartialPts): Promise<Pts> =>
  ptsApiClient
    .patch<PTSApiUpdate, AxiosResponse<PTSApi>>(getApiUrl(id), denormalizePts(ptsData))
    .then(normalizePtsResponse);

const createPtsList = async (list: NewPts[]): Promise<Pts[]> => {
  const { data } = await ptsApiClient.post<PTSApiCreate, AxiosResponse<PTSApi[]>>(
    getApiUrl("import"),
    list.map(denormalizePts),
  );

  return data.map(normalizePts);
};

const patchPtsList = async (list: PartialPts[]) => {
  const results = await Promise.allSettled(list.map(patchPts));

  return results.reduce<{ fulfilled: Pts[]; rejected: AxiosError[] }>(
    (acc, result) => {
      if (result.status === "fulfilled") {
        acc.fulfilled.push(result.value);
      } else {
        acc.rejected.push(result.reason);
      }
      return acc;
    },
    { fulfilled: [], rejected: [] },
  );
};

const createOrUpdatePtsList = async (list: (Pts | NewPts)[]): Promise<{ fulfilled: Pts[]; rejected: AxiosError[] }> => {
  const [toCreateList, toUpdateList] = partition(list, isNewPts);

  const [createResult, updateResult] = await Promise.allSettled([
    createPtsList(toCreateList),
    patchPtsList(toUpdateList),
  ]);

  const fulfilled: Pts[] = [];
  const rejected: AxiosError[] = [];

  if (createResult.status === "fulfilled") {
    fulfilled.push(...createResult.value);
  } else {
    rejected.push(createResult.reason);
  }

  if (updateResult.status === "fulfilled") {
    fulfilled.push(...updateResult.value.fulfilled);
    rejected.push(...updateResult.value.rejected);
  } else {
    rejected.push(updateResult.reason);
  }

  return { fulfilled, rejected };
};

const deletePtsList = (ids: string[]) => {
  return Promise.all(ids.map((id) => ptsApiClient.delete(getApiUrl(id))));
};

export const ptsApi = {
  getPtsList,
  getPtsByGroup,
  createPts,
  patchPts,
  deletePtsList,
  patchPtsList,
  createPtsList,
  createOrUpdatePtsList,
};

export type TPtsApi = typeof ptsApi;
