import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";

import styles from "./style.module.scss";

type Tick = {
  ts: number;
  left: string;
};

type Props = {
  bounds: [number, number];
  referenceValue: string;
};

const getTicks = (data: { bounds: [number, number]; axisWidth: number; minTickWidth: number }) => {
  const { bounds, axisWidth, minTickWidth } = data;
  const duration = bounds[1] - bounds[0];

  const toMs = 60 * 1000;
  const scale = duration < 20 * toMs ? 1 * toMs : 5 * toMs;

  let step = (minTickWidth / axisWidth) * duration;
  step = Math.ceil(step / scale) * scale;

  let ts = bounds[0];
  ts = Math.ceil(ts / step) * step;
  const start = bounds[0];

  const ticks: Tick[] = [];
  do {
    ticks.push({
      ts,
      left: ((ts - start) * 100) / duration + "%",
    });
    ts += step;
  } while (ts < bounds[1]);

  return ticks;
};

export const TimeAxis = observer(({ bounds, referenceValue }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [ticks, setTicks] = useState<Tick[]>([]);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const axisWidth = containerRef.current.offsetWidth;
    const minTickWidth = 48;

    const ticks = getTicks({
      bounds,
      axisWidth,
      minTickWidth,
    });

    setTicks(ticks);
  }, [...bounds]);

  const closestToZero = ticks.reduce((prev, v) => {
    if (Math.abs(v.ts) < prev) {
      return v.ts;
    }

    return prev;
  }, 0);

  const reducedReferenceValue = referenceValue.length < 8 ? referenceValue : "REF";

  return (
    <>
      <div className={styles.timeaxis} ref={containerRef}>
        {ticks.map((item) => (
          <div className={styles.tick} style={{ left: item.left }} key={item.ts}>
            <span className={styles.tickValue}>
              {item.ts === closestToZero ? reducedReferenceValue : item.ts / 1000 / 60}
            </span>
          </div>
        ))}
      </div>
      <div className={styles.axisContainer}>
        {ticks.map((item) => (
          <span style={{ left: item.left }} key={item.ts} className={styles.axis} />
        ))}
      </div>
    </>
  );
});
