import React, { useContext } from "react";

import { ModalHeader, PrimaryButton, Modal, ModalLabel } from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { FormattedMessage, useIntl } from "react-intl";
import { HomeContext } from "@services/react";

import s from "./style.module.scss";

export const UIAlertModal = observer(() => {
  const { uiAlert: notification, setUIAlert } = useContext(HomeContext);
  const intl = useIntl();

  if (!notification) {
    return null;
  }

  const { message, values, type } = notification;

  const onClose = () => setUIAlert(null);
  const isError = type === "error";

  return (
    <Modal className={s.uiAlert} onClose={onClose} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <ModalHeader
        title={
          isError
            ? intl.formatMessage({
                defaultMessage: "Error",
                description: "Error alert title",
              })
            : ""
        }
        onClose={isError ? onClose : undefined}
      />
      <ModalLabel className={s.text} title={intl.formatMessage(message, values as Record<string, string>)} />
      <PrimaryButton onClick={onClose}>
        <FormattedMessage defaultMessage="OK" description="Alert ok button text" />
      </PrimaryButton>
    </Modal>
  );
});
